import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Flex,
  Link as ChakraLink,
  Tab,
  TabList,
  Tabs,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Text,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import {Link, Navigate, Route, Routes, useSearchParams} from 'react-router-dom';
import AccountGrid from '../AccountGrid';
import {TabbedPage} from '../TabbedPage';
import {
  useCustomer,
  useIsFreeOrListBuilderCustomer,
  useMarkets,
} from '../../hooks/api/metadata';
import {EventNames, analytics} from '../../analytics';
import {PlanLabels, getSavesQuota} from '../../shared/customer';
import {useIsRescoring, useRefreshAccounts} from '../../hooks/api/scoringInfo';
import {Bold} from '../text';
import {useDiscoverCounts} from '../../hooks/api/discoverCounts';

const Discover = () => {
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  return isFreeOrListBuilderCustomer ? <FreemiumDiscover /> : <PaidDiscover />;
};

const FreemiumDiscover = () => {
  const {totalCount: numTotal, samSize: numDiscover} = useDiscoverCounts();

  const discoverCountText =
    (numDiscover ?? 0) > (numTotal ?? 0)
      ? ` ${numDiscover!.toLocaleString()} in `
      : ' ';
  const totalCountText = numTotal ? ` ${numTotal.toLocaleString()} ` : ' ';

  const customer = useCustomer();
  const {remaining: remainingSaves} = getSavesQuota(customer);
  const isRescoring = useIsRescoring();
  const triggerRefresh = useRefreshAccounts();
  const markets = useMarkets();

  return (
    <Flex direction="column">
      <Flex
        alignItems="baseline"
        bgColor="kgray.100"
        height="140px"
        gap={2}
        px={5}
        py={8}
        justifyContent="space-between"
      >
        <Box>
          <Box fontSize="2xl" mb={2} display={{base: 'none', md: 'block'}}>
            You&apos;re exploring the top{totalCountText}accounts from
            {discoverCountText}your market
          </Box>
          <Box fontSize="2xl" mb={2} display={{base: 'block', md: 'none'}}>
            You&apos;re exploring the top
            {numTotal ? ` ${numTotal?.toLocaleString()} ` : ' '}
            accounts from your market.
          </Box>
          <Box color="kgray.300" display={{base: 'none', lg: 'block'}}>
            Save &amp; Tag accounts to view and export them later.{' '}
            {remainingSaves > 2_500 && numDiscover < 5_000 ? (
              <>
                <Button
                  color="kblue.300"
                  fontWeight="normal"
                  isDisabled={isRescoring}
                  onClick={() => triggerRefresh.mutate()}
                  variant="link"
                >
                  Refresh data and load more
                </Button>
                .
              </>
            ) : (
              <>
                <ChakraLink
                  color="kblue.300"
                  href="https://docs.keyplay.io/en/articles/8880717-save-tag-and-sync-new-accounts"
                  target="_blank"
                >
                  Learn how to save & tag
                </ChakraLink>
                .
              </>
            )}
          </Box>
        </Box>
        <Flex gap="2" direction={{base: 'column', lg: 'row'}}>
          <Button
            as={Link}
            colorScheme="kbuttonblue"
            flexShrink="0"
            fontWeight="normal"
            to={`/markets/${markets[0].id}/define`}
            variant="outline"
          >
            Edit your SAM Filters
          </Button>
          <Button
            as={Link}
            colorScheme="kbuttonblue"
            flexShrink="0"
            fontWeight="normal"
            to={`/markets/${markets[0].id}`}
          >
            View & Adjust Scoring
          </Button>
        </Flex>
      </Flex>
      <Routes>
        <Route
          path="recommended"
          element={<AccountGrid gridView="recommended" />}
        />
        <Route path="*" element={<Navigate to="/discover/recommended" />} />
      </Routes>
      <StripeCallbackHandler />
    </Flex>
  );
};

const FreshCreditsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="normal" textAlign="center">
          You&apos;ve got fresh credits! 🎉
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FreshCreditsContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const FreshCreditsContent = () => {
  const customer = useCustomer();

  return (
    <Flex direction="column" gap="5" p="2" pb="4">
      <Text>Each Save uses 1 credit. Your credits are good for 6 months.</Text>
      <Flex direction="column" gap="2">
        <Box>
          With your <Bold>{PlanLabels[customer.plan.type]}</Bold> plan, you can:
        </Box>
        <UnorderedList color="kgray.300" ms="6" styleType="'> '" spacing="1">
          <ListItem>Prioritize with lookalikes + signals</ListItem>
          <ListItem>Save and export your accounts</ListItem>
          <ListItem>Sync new accounts to HubSpot CRM</ListItem>
        </UnorderedList>
      </Flex>
      <Text>
        <Box>Need 2-way CRM sync and ongoing account scoring?</Box>
        👉 <ChakraLink color="kblue.300">Request a demo</ChakraLink>
      </Text>
    </Flex>
  );
};

const StripeCallbackHandler = () => {
  const [queryParameters, setQueryParameters] = useSearchParams();
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (queryParameters.has('success')) {
      onOpen();

      queryParameters.delete('success');
      setQueryParameters(queryParameters);
    } else if (queryParameters.has('cancel')) {
      analytics.track(EventNames.listBuilderCreditsPurchaseCanceled);

      queryParameters.delete('cancel');
      setQueryParameters(queryParameters);
    }
  }, [onOpen, queryParameters, setQueryParameters]);

  return <FreshCreditsModal isOpen={isOpen} onClose={onClose} />;
};

const PaidDiscover = () => {
  return (
    <TabbedPage title="Discover">
      <Tabs variant="line">
        <TabList>
          <Tab as={Link} to="recommended" ml={24}>
            Recommended
          </Tab>
        </TabList>

        <Routes>
          <Route
            path="recommended"
            element={<AccountGrid gridView="recommended" />}
          />
          <Route path="*" element={<Navigate to="/discover/recommended" />} />
        </Routes>
      </Tabs>
    </TabbedPage>
  );
};

export default Discover;
