import {useQueryClient} from '@tanstack/react-query';
import {createApiQuery, useApiMutation} from './api';
import {
  GetModelTest,
  GetScoringModel,
  PostModelTest,
  PostScoringModel,
} from '../../shared/api/definitions';
import {useMarketId} from '../../components/Market/MarketIdProvider';
import {useIsFreemiumCustomer} from './metadata';
import {MetadataQueryKey} from '../../context/MetadataContext';

export const ScoringModelCacheKey = 'scoringModel';
export const ScoringModelKeys = {
  all: [ScoringModelCacheKey],
  market: (marketId: string) => [...ScoringModelKeys.all, marketId],
} as const;

export const useScoringModel = createApiQuery(
  '/scoringModel',
  GetScoringModel,
  ({marketId}) => ScoringModelKeys.market(marketId)
);

export const useScoringModelFromContext = () => {
  const marketId = useMarketId().toString();
  return useScoringModel({marketId});
};

export const usePublishScoringModel = () => {
  const queryClient = useQueryClient();
  const isFreemiumCustomer = useIsFreemiumCustomer();

  return useApiMutation('/scoringModel', PostScoringModel, {
    onSuccess: async () => {
      // TODO: only invalidate scoring model that was published
      await queryClient.invalidateQueries(ScoringModelKeys.all);
      await queryClient.invalidateQueries(ScoringModelTestKeys.all);
      if (isFreemiumCustomer) {
        await queryClient.invalidateQueries([MetadataQueryKey]);
      }
    },
  });
};

export const ScoringModelTestCacheKey = 'scoringModelTest';
export const ScoringModelTestKeys = {
  all: [ScoringModelTestCacheKey],
  market: (marketId: string) => [...ScoringModelTestKeys.all, marketId],
} as const;

export const useGetModelTest = createApiQuery(
  '/modelTest',
  GetModelTest,
  ({marketId}) => ScoringModelTestKeys.market(marketId),
  {
    staleTime: 1000 * 60 * 60, // keep data for an hour unless query is invalidated
  }
);

export const usePublishModelTestFile = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/modelTest', PostModelTest, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(ScoringModelTestKeys.all);
    },
  });
};
