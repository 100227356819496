import React, {useCallback, useEffect, useState} from 'react';
import {useAccountTypeahead} from '../hooks/api/accountTypeahead';
import {useDebounce} from 'usehooks-ts';
import Select, {StylesConfig} from 'react-select';
import {
  Box,
  BoxProps,
  Button,
  Image,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import {
  AccountTypeaheadResponse,
  AccountWithLogo,
} from '../shared/api/responses';
import {TrashCan} from '@carbon/icons-react';
import {useSimilarityState} from './Market/ModelBuilder/Similarity.state';
import {useCustomer} from '../hooks/api/metadata';
import _ from 'lodash';

type SelectAccount = AccountWithLogo;

const DropdownIndicator = () => (
  <Box color="kgray.300" height="24px" width="24px" ml="3" mr="0">
    <svg viewBox="0 0 24 24">
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Box>
);

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #EBE8EA',
    borderRadius: '10px',
    padding: '3px',
    marginTop: '3px',
    marginRight: '0px',
    fontSize: '16px',
    flexDirection: 'row-reverse',
    height: '58px',
    fontWeight: '300',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '300',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0, // remove padding around the list
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
} satisfies StylesConfig;

export const LookalikeTypeahead = (props: BoxProps) => {
  const similarityState = useSimilarityState();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 250);

  const accountTypeaheadQuery = useAccountTypeahead(debouncedSearch);

  const customer = useCustomer();
  const {maxLookalikes} = similarityState.getLimits(customer);

  const [typeaheadAccounts, setTypeaheadAccounts] =
    useState<AccountTypeaheadResponse>([]);

  // don't render empty typeahead while data is loading (causes flickering)
  useEffect(() => {
    if (
      accountTypeaheadQuery.isLoading ||
      _.isEqual(accountTypeaheadQuery.data, typeaheadAccounts)
    ) {
      return;
    }

    setTypeaheadAccounts(accountTypeaheadQuery.data ?? []);
  }, [
    accountTypeaheadQuery.data,
    accountTypeaheadQuery.isLoading,
    typeaheadAccounts,
  ]);

  const formatOptionLabel = useCallback(
    (data: {value: SelectAccount}) => (
      <Box
        display="flex"
        alignItems="center"
        mt="0"
        key={data.value._id.toString()}
      >
        <Image
          src={data.value.logoUrl}
          alt="icon"
          boxSize="32px"
          mr="2"
          ml="1"
          fallbackSrc="/accountFallback.png"
        />
        <Text fontWeight="thin" fontSize="14px">
          {data.value.name}
        </Text>
        <Text ml="2" fontWeight="thin" color="gray.500" fontSize="14px">
          {data.value.domain}
        </Text>
      </Box>
    ),
    []
  );

  const reversedListOfAccounts = [...similarityState.localState].reverse();
  const typeaheadDisabled = reversedListOfAccounts.length >= maxLookalikes;

  return (
    <Box {...props}>
      <Tooltip isDisabled={!typeaheadDisabled} label="Lookalike limit reached">
        <Box position="sticky" top={0} zIndex={1} bg="white" pt={2}>
          <Select
            isDisabled={typeaheadDisabled}
            menuPortalTarget={document.body}
            placeholder="Enter a company domain (e.g. nike.com)"
            noOptionsMessage={() => null}
            styles={customSelectStyles}
            components={{DropdownIndicator, IndicatorSeparator: null}}
            formatOptionLabel={formatOptionLabel}
            inputValue={search}
            onInputChange={(val: string) => setSearch(val)}
            isMulti={false}
            isClearable={false}
            hideSelectedOptions={true}
            controlShouldRenderValue={false}
            options={typeaheadAccounts.map((account) => ({
              value: account,
              label: account.domain,
            }))}
            onChange={(selected) => {
              if (selected) {
                similarityState.addLookalike(selected.value);
              }
            }}
            isOptionSelected={(option) =>
              similarityState.localState.some((selectedAccount) =>
                selectedAccount._id.equals(option.value._id)
              )
            }
          />
        </Box>
      </Tooltip>
      <UnorderedList listStyleType="none" marginLeft={0}>
        {reversedListOfAccounts.map((account) => (
          <ListItem
            key={account._id.toString()}
            border="1px solid #EBE8EA"
            borderRadius="10px"
            p={3}
            mt={3}
            fontSize="16px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Image
                  src={account.logoUrl}
                  alt="icon"
                  boxSize="32px"
                  mr="4"
                  ml="0.5"
                  fallbackSrc="/accountFallback.png"
                />
                <Text fontWeight="thin">{account.name}</Text>
                <Text ml="2" fontWeight="thin" color="gray.500">
                  {account.domain}
                </Text>
              </Box>
              <Button
                key="delete"
                fontWeight="500"
                fontSize="14"
                colorScheme="blue"
                variant="ghost"
                onClick={() => similarityState.removeLookalike(account)}
              >
                <TrashCan />
              </Button>
            </Box>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};
