import {constructZodEnumType, keys} from './util';
import _ from 'lodash';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {CountryCodeSchema} from './countries';

export type AccountStatus =
  | 'ok'
  | 'invalid' // validation failed
  | 'needs_review' // needs human review
  | 'unsafe_redirect' // account domain redirected and we think it might be an acquisition
  | 'error'; // unexpected error

export type AccountDomainStatus =
  | 'ok'
  | 'invalid' // bad domain
  | 'redirect' // domain redirected
  | 'not_searched' // didn't search (because linkedin was invalid)
  | 'not_found' // tried to find a domain that matches linkedin but couldn't
  | 'filled'; // value not provided, but we were able to fill from account lookup

// linkedin statuses have similar meaning to domain statuses
// 'override' indicates we replaced the customer provided input with a different value
// from account lookup
export type AccountLinkedInStatus = AccountDomainStatus | 'override';

export type AccountValidation = {
  timestamp: Date;
  status: AccountStatus;
  domainStatus: AccountDomainStatus;
  linkedInStatus: AccountLinkedInStatus;
};

export const PrimaryBusinessCategoryLabels = {
  aerospaceAndDefense: 'Aerospace & Defense',
  automotive: 'Automotive',
  businessServices: 'Business Services',
  commercialServices: 'Commercial Services',
  construction: 'Construction',
  consumerApp: 'Consumer App',
  consumerProducts: 'Consumer Products',
  consumerServices: 'Consumer Services',
  educationInstitutions: 'Education Institutions',
  eLearning: 'E-Learning',
  energyAndUtilities: 'Energy & Utilities',
  financialServicesAndInsurance: 'Financial Services & Insurance',
  fintech: 'Fintech',
  governmentEntitites: 'Government Entities',
  healthTechnology: 'Health Technology',
  healthcare: 'Healthcare',
  hospitalityAndRestaurants: 'Hospitality & Restaurants',
  industrialGoods: 'Industrial Goods',
  manufacturing: 'Manufacturing',
  mediaAndEntertainment: 'Media & Entertainment',
  onlineMarketplace: 'Online Marketplace',
  primaryIndustries: 'Primary Industries',
  realEstate: 'Real Estate',
  residentialServices: 'Residential Services',
  retailAndEcommerce: 'Retail & E-Commerce',
  saasAndCloud: 'SaaS & Cloud',
  telecommunications: 'Telecommunications',
  transportationAndLogistics: 'Transportation & Logistics',
} as const;

export const PrimaryBusinessCategories = keys(PrimaryBusinessCategoryLabels);

export const PrimaryBusinessCategorySchema = constructZodEnumType(
  PrimaryBusinessCategories
);
export type PrimaryBusinessCategory = z.infer<
  typeof PrimaryBusinessCategorySchema
>;
export const isPrimaryBusinessCategory = zodTypeguard(
  PrimaryBusinessCategorySchema
);

export type PrimaryBusinessCategoryLabel =
  (typeof PrimaryBusinessCategoryLabels)[PrimaryBusinessCategory];

export const PrimaryLabelsToCategories = _.invert(
  PrimaryBusinessCategoryLabels
) as Record<PrimaryBusinessCategoryLabel, PrimaryBusinessCategory>;

// secondary categories can be found here: https://docs.google.com/spreadsheets/d/11eyQbRCQmBHNCy2md_qHiVIdv18oHULF5hJPXa_ySX0/edit#gid=538822800
export const SecondaryBusinessCategoryLabels = {
  accountingAndFinanceTools: 'Accounting & Finance Tools',
  apparelAndFashion: 'Apparel & Fashion',
  artificialIntelligence: 'Artificial Intelligence (AI)',
  banking: 'Banking',
  beautyAndPersonalCare: 'Beauty & Personal Care',
  biotech: 'Biotech',
  bnpl: 'BNPL',
  businessProcessOutsourcing: 'Business Process Outsourcing',
  cateringAndFoodServices: 'Catering & Food Services',
  chemicals: 'Chemicals',
  civilEngineering: 'Civil Engineering',
  cleaning: 'Cleaning',
  cloudInfrastructure: 'Cloud Infrastructure',
  collaborationAndProductivity: 'Collaboration & Productivity',
  commercialConstruction: 'Commercial Construction',
  commercialMachinery: 'Commercial Machinery',
  consulting: 'Consulting',
  contractManufacturing: 'Contract Manufacturing',
  creditCards: 'Credit Cards',
  cryptocurrencies: 'Cryptocurrencies',
  customSoftwareDevelopment: 'Custom Software Development',
  customerSupport: 'Customer Support',
  dataOrAnalyticsOrBi: 'Data/Analytics/BI',
  designTools: 'Design Tools',
  developerTools: 'Developer Tools',
  digitalBanking: 'Digital Banking',
  dtcHealthServices: 'DTC Health Services',
  electrical: 'Electrical',
  electronicsAndAppliances: 'Electronics & Appliances',
  facilitiesMaintenance: 'Facilities Maintenance',
  foodAndBeverage: 'Food & Beverage',
  foodAndGroceryDelivery: 'Food & Grocery Delivery',
  gaming: 'Gaming',
  generalContracting: 'General Contracting',
  generativeAi: 'Generative AI',
  gigEconomy: 'Gig Economy',
  healthAndWellnessApps: 'Health & Wellness Apps',
  healthBenefits: 'Health Benefits',
  healthInsurance: 'Health Insurance',
  homeAndLifestyle: 'Home & Lifestyle',
  homeServices: 'Home Services',
  hospitals: 'Hospitals',
  hrAndRecruitingAgencies: 'HR & Recruiting Agencies',
  hrTech: 'HR Tech',
  hvac: 'HVAC',
  insurance: 'Insurance',
  interiorDesign: 'Interior Design',
  internetServiceProviders: 'Internet Service Providers',
  investingAndBrokerage: 'Investing & Brokerage',
  itServices: 'IT Services',
  jobSearchApp: 'Job Search App',
  landDevelopment: 'Land Development',
  landscaping: 'Landscaping',
  lawFirms: 'Law Firms',
  lendingAndBorrowing: 'Lending & Borrowing',
  longTermCare: 'Long-Term Care',
  marketingAndAdAgencies: 'Marketing & Ad Agencies',
  marketingTech: 'Marketing Tech',
  medicalResearch: 'Medical Research',
  onlineDating: 'Online Dating',
  onlineInvesting: 'Online Investing',
  onlineNews: 'Online News',
  onlineSearch: 'Online Search',
  outpatientClinics: 'Outpatient Clinics',
  packagingServices: 'Packaging Services',
  painting: 'Painting',
  paymentsAndTransactions: 'Payments & Transactions',
  personalFinance: 'Personal Finance',
  personalSecurity: 'Personal Security',
  pestControl: 'Pest Control',
  petProducts: 'Pet Products',
  pharmaceuticals: 'Pharmaceuticals',
  plumbing: 'Plumbing',
  poolsAndHotTubs: 'Pools & Hot Tubs',
  pressureWashing: 'Pressure Washing',
  primaryCarePhysicians: 'Primary Care Physicians',
  professionalServices: 'Professional Services',
  propertyManagement: 'Property Management',
  rehabilitationCenters: 'Rehabilitation Centers',
  residentialConstruction: 'Residential Construction',
  restoration: 'Restoration',
  roofing: 'Roofing',
  saasForConstruction: 'SaaS for Construction',
  saasForEducation: 'SaaS for Education',
  saasForFinancialServices: 'SaaS for Financial Services',
  saasForHealthcare: 'SaaS for Healthcare',
  saasForHospitality: 'SaaS for Hospitality',
  saasForLegal: 'SaaS for Legal',
  saasForRestaurants: 'SaaS for Restaurants',
  saasForRetailAndEcommerce: 'SaaS for Retail & eCommerce',
  salesTech: 'Sales Tech',
  securityAndCompliance: 'Security & Compliance',
  securityAndSurveillance: 'Security & Surveillance',
  shoppingApp: 'Shopping App',
  socialMediaAndMessaging: 'Social Media & Messaging',
  solarEnergy: 'Solar Energy',
  specializedClinics: 'Specialized Clinics',
  specialtyTrades: 'Specialty Trades',
  spendManagement: 'Spend Management',
  sports: 'Sports',
  sportsAndFitness: 'Sports & Fitness',
  streamingMedia: 'Streaming Media',
  studiosAndProduction: 'Studios & Production',
  ticketsAndEvents: 'Tickets & Events',
  toysAndGames: 'Toys & Games',
  traditionalBroadcasting: 'Traditional Broadcasting',
  transportation: 'Transportation',
  travel: 'Travel',
  urgentCareCenters: 'Urgent Care Centers',
  verticalSaas: 'Vertical SaaS',
  virtualCare: 'Virtual Care',
  wasteManagement: 'Waste Management',
  wearablesAndMonitoring: 'Wearables & Monitoring',
  workflowAutomation: 'Workflow Automation',
} as const;

export const SecondaryBusinessCategories = keys(
  SecondaryBusinessCategoryLabels
);

export const SecondaryBusinessCategorySchema = constructZodEnumType(
  SecondaryBusinessCategories
);
export type SecondaryBusinessCategory = z.infer<
  typeof SecondaryBusinessCategorySchema
>;
export const isSecondaryBusinessCategory = zodTypeguard(
  SecondaryBusinessCategorySchema
);

export type SecondaryBusinessCategoryLabel =
  (typeof SecondaryBusinessCategoryLabels)[SecondaryBusinessCategory];

export const SecondaryLabelsToCategories = _.invert(
  SecondaryBusinessCategoryLabels
) as Record<string, SecondaryBusinessCategory>;

export const BusinessAudienceLabels = {
  b2b: 'B2B',
  b2c: 'B2C',
};

export const BusinessAudiences = keys(BusinessAudienceLabels);

export const BusinessAudienceSchema = constructZodEnumType(BusinessAudiences);
export type BusinessAudience = z.infer<typeof BusinessAudienceSchema>;

export const BusinessAudienceLabelsToAudience = _.invert(
  BusinessAudienceLabels
) as Record<string, BusinessAudience>;

const InsufficientGptDataSchema = z.object({
  timestamp: z.date(),
  insufficientData: z.literal(true),
});
export type InsufficientGptData = z.infer<typeof InsufficientGptDataSchema>;
export const isInsufficientGptData = zodTypeguard(InsufficientGptDataSchema);

const GptDataSchema = z.object({
  timestamp: z.date(),

  legacyBusinessModelDescription: z.string().optional(),

  businessModelSummary: z.string(),
  businessOverview: z.object({
    businessAndMarket: z.string(),
    historyAndCurrentState: z.string(),
    personalityAndCulture: z.string(),
  }),
  categories: z.object({
    primary: z.array(PrimaryBusinessCategorySchema),
    secondary: z.array(SecondaryBusinessCategorySchema),
    audience: z.array(BusinessAudienceSchema),
  }),

  additionalFields: z
    .object({
      timestamp: z.date(),

      businessOverview10Word: z.string(),
      businessOverview50Word: z.string(),
      competitors: z.array(z.string()),
      hqCity: z.string().optional(),
      hqCountry: CountryCodeSchema.optional(),
      hqState: z.string().optional(),
      stockTicker: z.string().optional(),
      yearFounded: z.number().optional(),
    })
    .optional(),
});
export type GptData = z.infer<typeof GptDataSchema>;

const CrunchbaseDataSchema = z.object({
  url: z.string().url(),
  amountRaised: z.number().optional(),
  amountRaisedInUsd: z.number().optional(),
  currencyType: z.string().optional(),
  lastFundingDate: z.string().optional(),
  numberOfRounds: z.number().optional(),
});
export type CrunchbaseData = z.infer<typeof CrunchbaseDataSchema>;

const LinkedInStaticDataSchema = z.object({
  timestamp: z.date(),
  name: z.string().optional(),
  crunchbase: CrunchbaseDataSchema.optional(),
  description: z.string().optional(),
  industry: z.string().optional(),
  specialties: z.array(z.string()).optional(),
  stockTicker: z.string().optional(),
  tagline: z.string().optional(),
  yearFounded: z.number().optional(),
});
export type LinkedInStaticData = z.infer<typeof LinkedInStaticDataSchema>;

const StaticAccountDataSchema = z.object({
  gpt: z.union([GptDataSchema, InsufficientGptDataSchema]).optional(),

  meta: z
    .object({
      timestamp: z.date(),
      description: z.string().optional(),
      keywords: z.string().optional(),
      title: z.string().optional(),
    })
    .optional(),

  linkedIn: LinkedInStaticDataSchema.optional(),
});
export type StaticAccountData = z.infer<typeof StaticAccountDataSchema>;
