import React, {useCallback} from 'react';
import {
  Box,
  ButtonProps,
  Code,
  useToast,
  Tooltip,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import {useDropzone} from 'react-dropzone';
import {validateFile} from '../../../lib/validateFile';
import {z} from 'zod';
import {zodTypeguard} from '../../../shared/api/helpers';
import {MaxModelTestRows, ModelTestFile} from '../../../shared/scoring';

const LocalModelTestRowSchema = z.object({
  Domain: z.string(),
  Groups: z.string(),
});
const isLocalModelTestRow = zodTypeguard(LocalModelTestRowSchema);

interface ModelTestImportButtonProps extends ButtonProps {
  onModelTestUploaded: (file: ModelTestFile) => void;
}

export const ModelTestImportButton = ({
  onModelTestUploaded,
  children,
  ...rest
}: ModelTestImportButtonProps) => {
  const toast = useToast();

  const showErrorToast = useCallback(() => {
    toast({
      title: 'Invalid test configuration',
      description: `Please ensure your CSV file has "Domain" and "Groups" columns and is less than ${MaxModelTestRows} rows.`,
      status: 'error',
    });
  }, [toast]);

  const {open} = useDropzone({
    accept: {'text/csv': ['.csv']},
    onDropAccepted: (files) => {
      const file = files[0];
      if (!file) {
        return;
      }

      validateFile({
        maxRows: MaxModelTestRows,
        onFileValidate: (errors, data) => {
          if (errors.size) {
            showErrorToast();
            return;
          }

          const rows = data.map(({Domain, Groups}) => ({
            domain: Domain,
            groups: Groups.split(',').map((group) => group.trim()),
          }));
          onModelTestUploaded({rows, fileName: file.name});
        },
        rowSchemaTypeguard: isLocalModelTestRow,
        selectedFile: file,
      });
    },
    onDropRejected: showErrorToast,
    maxSize: 100_000, // in bytes
    multiple: false,
  });

  return (
    <Tooltip
      hasArrow
      label={
        <Flex direction="column" gap="4" my="2">
          <Text>
            Test config. The CSV should include two columns, one for Domains and
            another for Groups.
          </Text>
          <Code>
            <Box>Domain,Groups</Box>
            <Box>example1.com,&quot;Group 1&quot;</Box>
            <Box>example2.com,&quot;Group 1, Group 2&quot;</Box>
          </Code>
        </Flex>
      }
      placement="top"
    >
      <Button
        colorScheme="kbuttonblue"
        fontSize="sm"
        fontWeight="normal"
        onClick={(event) => {
          event.currentTarget.blur(); // hide tooltip
          open();
        }}
        variant="outline"
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
