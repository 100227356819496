import React from 'react';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {ModelTestImportButton} from './ModelTestImportButton';
import {ModelTestGroupResults} from '../../../shared/scoring';
import {
  TableContainer,
  Table,
  Text,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Spacer,
  Box,
  Spinner,
  Button,
  useTheme,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  useGetModelTest,
  usePublishModelTestFile,
} from '../../../hooks/api/scoringModel';
import {useMarketId} from '../MarketIdProvider';
import {Parser} from '@json2csv/plainjs';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import FileSaver from 'file-saver';
import {useCustomer} from '../../../hooks/api/metadata';
import _ from 'lodash';
import {AccountTier} from '../../../shared/scoredAccounts';
import {ValidateDomainsButton} from './ValidateDomainsButton';
import {Cell, Pie, PieChart, Tooltip} from 'recharts';
import {entries} from '../../../shared/util';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';

const getTierCell = (
  counts: Record<AccountTier, number>,
  tier: AccountTier
) => {
  const count = counts[tier];
  const sum = _.sum(Object.values(counts));
  const percentage = ((count / sum) * 100).toFixed(2);

  return <>{percentage}%</>;
};

const TierPieChart = ({
  tierCounts,
}: {
  tierCounts: Record<AccountTier, number>;
}) => {
  const theme = useTheme();
  const colors = {
    A: theme.colors.kgreen[400],
    B: theme.colors.kgreen[200],
    C: theme.colors.kyellow[400],
    D: theme.colors.kred[400],
  };

  const data = entries(tierCounts)
    .map(([tier, value]) => ({
      tier,
      value,
    }))
    .filter(({value}) => value > 0);

  return (
    <PieChart width={50} height={50}>
      <Pie data={data} innerRadius={12} outerRadius={24} dataKey="value">
        {data.map(({tier}) => (
          <Cell key={`cell-${tier}`} fill={colors[tier]} />
        ))}
      </Pie>
      <Tooltip
        content={({active, payload, coordinate}) => {
          const {x, y} = coordinate ?? {};
          const item = payload?.[0]?.payload;

          if (!active || !item || !x || !y) {
            return null;
          }

          return (
            <Box
              bg="white"
              border="1px solid #E8E8EB"
              p={1}
              transform={`translate(${x}px, ${y - 40}px)`}
            >{`Tier ${item.tier} - ${item.value}`}</Box>
          );
        }}
      />
    </PieChart>
  );
};

const columnHelper = createColumnHelper<ModelTestGroupResults[0]>();
const columns = [
  columnHelper.accessor('group', {
    id: 'group',
    header: 'Group',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('tierCounts', {
    id: 'pie',
    header: '',
    cell: (info) => <TierPieChart tierCounts={info.getValue()} />,
  }),
  columnHelper.accessor('tierCounts', {
    id: 'total',
    header: 'Total',
    cell: (info) => _.sum(Object.values(info.getValue())),
  }),
  columnHelper.accessor('tierCounts', {
    id: 'tierA',
    header: 'A',
    cell: (info) => getTierCell(info.getValue(), 'A'),
  }),
  columnHelper.accessor('tierCounts', {
    id: 'tierB',
    header: 'B',
    cell: (info) => getTierCell(info.getValue(), 'B'),
  }),
  columnHelper.accessor('tierCounts', {
    id: 'tierC',
    header: 'C',
    cell: (info) => getTierCell(info.getValue(), 'C'),
  }),
  columnHelper.accessor('tierCounts', {
    id: 'tierD',
    header: 'D',
    cell: (info) => getTierCell(info.getValue(), 'D'),
  }),
];

const ModelTestGroupResultsTable = ({
  groups,
}: {
  groups: ModelTestGroupResults;
}) => {
  const table = useReactTable({
    columns,
    data: groups,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id} _groupHover={{bg: 'kgray.100'}}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const ModelTest = () => {
  const isAdmin = useIsKeyplayAdmin();
  const marketId = useMarketId();
  const customer = useCustomer();
  const resolver = useScoringSignalResolver();

  const modelTest = useGetModelTest({marketId: marketId.toString()});
  const publishModelTest = usePublishModelTestFile();

  const {data, isFetching} = modelTest;
  const {groups, results, counts, fileName} = data ?? {};

  const downloadSignalCountsCsv = async () => {
    if (!counts) {
      return;
    }

    const csv = new Parser().parse(
      counts.map(({signal, groupCounts}) => {
        const resolvedSignal = resolver(signal);

        return {
          'Signal Category': resolvedSignal?.category ?? 'unknown',
          'Signal Name': resolvedSignal?.label ?? signal,
          ...groupCounts,
        };
      })
    );
    const blob = new Blob([csv], {type: 'text/csv'});
    FileSaver.saveAs(blob, `signalCounts-${customer.name}`);
  };

  const downloadResultsCsv = async () => {
    if (!results) {
      return;
    }

    const csv = new Parser().parse(
      results.map(({domain, score, tier, groups}) => {
        return {
          Domain: domain,
          'Overall Fit': score.toFixed(2),
          Tier: tier,
          Groups: groups.join(', '),
        };
      })
    );
    const blob = new Blob([csv], {type: 'text/csv'});
    FileSaver.saveAs(blob, `testResults-${customer.name}`);
  };

  return (
    <WrapperFlex>
      <ScrollableFlex px={6}>
        <HStack my={4}>
          {isAdmin && (
            <ValidateDomainsButton>Validate Domains</ValidateDomainsButton>
          )}
          <Spacer />
          {!isFetching && (
            <Text pr={8}>
              <strong>{fileName}</strong>
            </Text>
          )}
          <Button
            isDisabled={!results?.length}
            colorScheme="kbuttonblue"
            fontSize="sm"
            fontWeight="normal"
            onClick={downloadResultsCsv}
            variant="outline"
          >
            Download Results
          </Button>
          {isAdmin && (
            <>
              <Button
                isDisabled={!counts?.length}
                colorScheme="kbuttonblue"
                fontSize="sm"
                fontWeight="normal"
                onClick={downloadSignalCountsCsv}
                variant="outline"
              >
                Download Counts
              </Button>
              <ModelTestImportButton
                onModelTestUploaded={(file) =>
                  publishModelTest.mutate({marketId, file})
                }
              >
                Upload CSV
              </ModelTestImportButton>
            </>
          )}
        </HStack>
        <Box>
          {isFetching && (
            <HStack>
              <Spacer />
              <Spinner />
            </HStack>
          )}
          {!isFetching &&
            (groups ? (
              <ModelTestGroupResultsTable groups={groups} />
            ) : (
              <HStack>
                <Spacer />
                <Text>Upload a CSV to run model test.</Text>
              </HStack>
            ))}
        </Box>
      </ScrollableFlex>
    </WrapperFlex>
  );
};
