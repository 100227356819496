import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router';
import {MarketsList} from './MarketsList';
import {MarketBuilder} from './MarketBuilder/MarketBuilder';
import {useVerifiedMarketId} from '../../hooks/api/metadata';
import {MarketIdProvider} from './MarketIdProvider';

export const Markets = () => {
  return (
    <Routes>
      <Route path=":marketId/*" element={<Market />} />
      <Route index element={<MarketsList />} />
    </Routes>
  );
};

const Market = () => {
  const {marketId} = useParams();
  const verifiedMarketId = useVerifiedMarketId(marketId);

  if (marketId && !verifiedMarketId.equals(marketId)) {
    return <Navigate to="/markets" />;
  }

  return (
    <MarketIdProvider marketId={verifiedMarketId}>
      <Routes>
        <Route path="*" element={<MarketBuilder />} />
      </Routes>
    </MarketIdProvider>
  );
};
