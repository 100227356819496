import {CountryCode} from './countries';
import {StrictExclude, keys, typeguardFromArray} from './util';

export interface AccountFieldTypes {
  finalDomain: string;
  finalLinkedIn: string;

  jobBoardURL: string;
  keyplayJobBoardUrl: string;
  openRolePercentage: number;
  recruitingVelocity: number;
  trafficRank: number;

  employeeCount: number;
  followerCount: number;
  hqCity: string;
  hqCountry: CountryCode;
  hqPostalCode: string;
  hqState: string;
  linkedInJobCount: number;
  locationCities: string[];
  locationCount: number;
  locationCountries: CountryCode[];
  locationStates: string[];
  name: string;
  stockTicker: string;
  tagline: string;
  yearFounded: number;

  blogs: number;
  events: number;
  integrations: number;
  platform: number;
  products: number;
  solutions: number;
  verticals: number;
  webinars: number;

  // Hiring
  hiring: number;
  hiringABM: number;
  hiringAEorAM: number;
  hiringAi: number;
  hiringBrandOrComms: number;
  hiringCommunity: number;
  hiringContentMarketing: number;
  hiringCustomerSuccess: number;
  hiringDataTeam: number;
  hiringDemandGen: number;
  hiringDesign: number;
  hiringEng: number;
  hiringFinance: number;
  hiringGrowth: number;
  hiringHackerRankSkills: number;
  hiringHackerRankSkillsEmea: number;
  hiringHackerRankSkillsApac: number;
  hiringHackerRankSkillsAmer: number;
  hiringHR: number;
  hiringMarketing: number;
  hiringMarketingOps: number;
  hiringOtherOps: number;
  hiringProduct: number;
  hiringProductMarketing: number;
  hiringRevOrSalesOps: number;
  hiringSDRorBDR: number;
  hiringSales: number;
  hiringSalesEnablement: number;
  hiringSalesOrSolutionsEng: number;
  hiringSoftwareEng: number;
  hiringSpecialists: number;
  hiringSupport: number;

  openContractorRoles: number;

  // URLs
  pricingPage: string;
  productPage: string;
  demoPage: string;
  signupPage: string;
  partnersPage: string;
  slackCommunityPage: string;

  // enhanced LinkedIn fields
  sixMonthGrowth: number;
  oneYearGrowth: number;
  numberOfFundingRounds: number;
  lastFundingDate: string;
  totalFundingRaised: number;
  linkedInAdsCount: number;
  linkedInLogoUrl: string;

  // month-over-month changes
  oneMonthFollowerChange: number;
  sixMonthFollowerChange: number;
  oneMonthHeadcountGrowth: number;
  oneMonthLinkedInAdsChange: number;
  oneMonthOpenRolesChange: number;
  oneMonthRecruitingVelocityChange: number;
  oneMonthTotalFundingChange: number;
  oneMonthHiringCustomerSuccessChange: number;
  oneMonthHiringEngChange: number;
  oneMonthHiringFinanceChange: number;
  oneMonthHiringHRChange: number;
  oneMonthHiringMarketingChange: number;
  oneMonthHiringProductChange: number;
  oneMonthHiringSalesChange: number;
}

export const AccountFieldLabels = {
  finalDomain: 'Final Domain',
  finalLinkedIn: 'Final LinkedIn',

  jobBoardURL: 'Job Board URL',
  keyplayJobBoardUrl: 'Original Job Board URL',
  openRolePercentage: 'Open Roles / Total Headcount',
  recruitingVelocity: 'Recruiting Velocity Score',
  trafficRank: 'Traffic Rank',

  employeeCount: 'Employees',
  followerCount: 'Follower Count',
  hqCity: 'HQ City',
  hqCountry: 'HQ Country',
  hqPostalCode: 'HQ Postal Code',
  hqState: 'HQ State',
  linkedInJobCount: 'LinkedIn Job Count',
  locationCities: 'Location Cities',
  locationCount: 'Location Count',
  locationCountries: 'Location Countries',
  locationStates: 'Location States',
  name: 'Company Name',
  stockTicker: 'Stock Ticker',
  tagline: 'Tagline',
  yearFounded: 'Year Founded',

  blogs: 'Blogs',
  events: 'Events',
  integrations: 'Integrations',
  platform: 'Platform',
  products: 'Products',
  solutions: 'Solutions',
  verticals: 'Verticals',
  webinars: 'Webinars',

  hiring: 'Hiring',
  hiringABM: 'Hiring ABM',
  hiringAEorAM: 'Hiring AE/AM',
  hiringAi: 'Hiring AI',
  hiringBrandOrComms: 'Hiring Brand/Comms',
  hiringCommunity: 'Hiring Community',
  hiringContentMarketing: 'Hiring Content Marketing',
  hiringCustomerSuccess: 'Hiring Cust Success',
  hiringDataTeam: 'Hiring Data Team',
  hiringDemandGen: 'Hiring Demand Gen',
  hiringDesign: 'Hiring Design',
  hiringEng: 'Hiring Eng',
  hiringFinance: 'Hiring Finance',
  hiringGrowth: 'Hiring Growth',
  hiringHackerRankSkills: 'Hiring HackerRank Skills',
  hiringHackerRankSkillsEmea: 'Hiring HackerRank Skills - EMEA',
  hiringHackerRankSkillsApac: 'Hiring HackerRank Skills - APAC',
  hiringHackerRankSkillsAmer: 'Hiring HackerRank Skills - AMER',
  hiringHR: 'Hiring HR',
  hiringMarketing: 'Hiring Marketing',
  hiringMarketingOps: 'Hiring Marketing Ops',
  hiringOtherOps: 'Hiring Other Ops',
  hiringProduct: 'Hiring Product',
  hiringProductMarketing: 'Hiring Product Marketing',
  hiringRevOrSalesOps: 'Hiring Rev/Sales Ops',
  hiringSDRorBDR: 'Hiring SDR/BDR',
  hiringSales: 'Hiring Sales',
  hiringSalesEnablement: 'Hiring Sales Enablement',
  hiringSalesOrSolutionsEng: 'Hiring Sales/Solutions Eng',
  hiringSoftwareEng: 'Hiring Software Eng',
  hiringSpecialists: 'Hiring Specialists',
  hiringSupport: 'Hiring Support',

  openContractorRoles: 'Open Contractor Roles',

  demoPage: 'Demo Page',
  partnersPage: 'Partners Page',
  pricingPage: 'Pricing Page',
  productPage: 'Product Page',
  signupPage: 'Signup Page',
  slackCommunityPage: 'Slack Community Page',

  sixMonthGrowth: 'Headcount Growth (6 mo)',
  oneYearGrowth: 'Headcount Growth (Y/Y)',
  numberOfFundingRounds: 'Funding Rounds',
  lastFundingDate: 'Last Funding Date',
  totalFundingRaised: 'Total Funding',
  linkedInAdsCount: 'LinkedIn Ads Count',
  linkedInLogoUrl: 'Logo Url',

  oneMonthFollowerChange: 'Follower Change (1 mo)',
  sixMonthFollowerChange: 'Follower Change (6 mo)',

  oneMonthHeadcountGrowth: 'Headcount Growth (1 mo)',
  oneMonthLinkedInAdsChange: 'M/M LinkedIn Ads Change',
  oneMonthOpenRolesChange: 'M/M Open Roles Change',
  oneMonthRecruitingVelocityChange: 'M/M Recruiting Velocity Change',
  oneMonthTotalFundingChange: 'M/M Total Funding Change',
  oneMonthHiringCustomerSuccessChange: 'M/M Hiring Customer Success Change',
  oneMonthHiringEngChange: 'M/M Hiring Engineering Change',
  oneMonthHiringFinanceChange: 'M/M Hiring Finance Change',
  oneMonthHiringHRChange: 'M/M Hiring HR Change',
  oneMonthHiringMarketingChange: 'M/M Hiring Marketing Change',
  oneMonthHiringProductChange: 'M/M Hiring Product Change',
  oneMonthHiringSalesChange: 'M/M Hiring Sales Change',
} satisfies Record<AccountField, string>;

export const AccountFieldList = keys(AccountFieldLabels);
export const isAccountField = typeguardFromArray(AccountFieldList);

// This is used in few different places:
//  - we write these fields to ScoredAccount
//  - these fields are always included in exports
//  - these fields are returned from our API
//
// TODO: we could probably pull this apart into different types
// for different use cases (this might also make it easier to write
// less data to ScoredAccount)
export const CoreAccountFieldList = [
  'finalDomain',
  'finalLinkedIn',

  'jobBoardURL',
  'openRolePercentage',
  'recruitingVelocity',
  'trafficRank',

  'employeeCount',
  'followerCount',
  'hqCity',
  'hqCountry',
  'hqPostalCode',
  'hqState',
  'locationCities',
  'locationCount',
  'locationCountries',
  'locationStates',
  'name',
  'stockTicker',
  'tagline',
  'yearFounded',

  'hiring',
  'hiringEng',
  'hiringSales',
  'hiringSoftwareEng',

  'linkedInLogoUrl',

  // change fields
  'oneMonthFollowerChange',
  'sixMonthFollowerChange',
  'oneMonthHeadcountGrowth',
  'oneMonthLinkedInAdsChange',
  'oneMonthOpenRolesChange',
  'oneMonthRecruitingVelocityChange',
  'oneMonthTotalFundingChange',
  'oneMonthHiringCustomerSuccessChange',
  'oneMonthHiringEngChange',
  'oneMonthHiringFinanceChange',
  'oneMonthHiringHRChange',
  'oneMonthHiringMarketingChange',
  'oneMonthHiringProductChange',
  'oneMonthHiringSalesChange',

  // extended linkedin fields
  'sixMonthGrowth',
  'lastFundingDate',
  'totalFundingRaised',
] satisfies readonly AccountField[];

export type AccountFieldLabel =
  (typeof AccountFieldLabels)[keyof typeof AccountFieldLabels];

export type AccountField = keyof AccountFieldTypes;
export type CoreAccountField = (typeof CoreAccountFieldList)[number];
export type OptionalAccountField = StrictExclude<
  AccountField,
  CoreAccountField
>;

export type AccountFields = Partial<AccountFieldTypes>;
