import {useMemo} from 'react';
import {useSamDefinitionSize} from '../../hooks/api/samDefinition';
import {useSamEditorStore} from './useSamEditorStore';
import _ from 'lodash';
import {useIsFreemiumCustomer} from '../../hooks/api/metadata';
import {useDebounce} from 'usehooks-ts';

export const useSamDefinitionLimits = () => {
  const {isEmpty, toServerState} = useSamEditorStore();
  const isFreemiumCustomer = useIsFreemiumCustomer();

  // Memoize the server state to maintain a stable reference
  const serverState = useMemo(() => {
    return toServerState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(toServerState())]);
  const debouncedServerState = useDebounce(serverState, 250);

  const samDefinitionSize = useSamDefinitionSize({
    enabled: isFreemiumCustomer && !isEmpty(),
    samDefinition: debouncedServerState,
  });

  const refetchPending =
    isFreemiumCustomer && !_.isEqual(serverState, debouncedServerState);

  return {
    withinLimits:
      !isFreemiumCustomer || isEmpty()
        ? true
        : samDefinitionSize.data?.withinLimits ?? undefined,
    isFetching: refetchPending || samDefinitionSize.isFetching,
  };
};
