import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import {BucketType, useScoringModelSignalStore} from './SignalAssignmentStore';
import {useDrop} from 'react-dnd';
import {
  ScoringCategories,
  ScoringCategory,
  ScoringSignal,
} from '../../../shared/signals';
import {DraggableSignal, DragItemType} from '../../signals';
import {ScoringCategoryColors} from '../../theme';
import {SignalWeightPopover} from './SignalWeightPopover';
import {SignalWeightsModal} from './SignalWeightsModal';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import {captureMessage} from '@sentry/react';

interface SignalsBucketProps extends BoxProps {
  title: string;
  bucketName: BucketType;
}

// One of the positive / negative buckets.
export const SignalsBucket = ({
  title,
  bucketName,
  ...remainingBoxProps
}: SignalsBucketProps) => {
  const {
    getEverySignalBucketSorted,
    assignBucket,
    getSignalWeight,
    hasInitialized: hasSignalStoreInitialized,
  } = useScoringModelSignalStore();
  const signalAssignments = getEverySignalBucketSorted();
  const scoringSignalResolver = useScoringSignalResolver();

  const {isOpen, onClose, onOpen} = useDisclosure();

  const [{isOver, tagCategory}, drop] = useDrop(() => ({
    // accepts all categories
    accept: ScoringCategories,
    drop: (item: DragItemType) => assignBucket(item.signal, bucketName),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      tagCategory: monitor.getItemType(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const tags: React.ReactNode[] = [];
  for (const signal in signalAssignments) {
    const scoringSignal = scoringSignalResolver(signal);
    if (!scoringSignal) {
      captureMessage(`unknown draggable signal ${signal}`, 'error');
      continue;
    }

    const weight = getSignalWeight(signal);
    if (signalAssignments[signal as ScoringSignal] === bucketName) {
      const categoryColor = ScoringCategoryColors[scoringSignal.category];
      tags.push(
        <SignalWeightPopover signal={signal}>
          {({isOpen}) => (
            <DraggableSignal
              signal={signal as ScoringSignal}
              key={signal}
              weight={weight}
              borderColor={isOpen ? `${categoryColor}.200` : 'transparent'}
              _hover={
                // Disable hover effect if popover is open
                isOpen ? {} : {bg: `${categoryColor}.100`}
              }
            />
          )}
        </SignalWeightPopover>
      );
    }
  }

  const droppableBorderColor =
    tagCategory &&
    `${ScoringCategoryColors[tagCategory as ScoringCategory]}.50`;
  const shouldShowDropBorder = isOver && droppableBorderColor;
  return (
    <Box mb={6} {...remainingBoxProps}>
      <SignalWeightsModal
        defaultBucket={bucketName}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        fontWeight={500}
        fontSize="sm"
        textTransform="uppercase"
        mb={2}
        color="#000"
        alignItems="center"
      >
        {title}
        <Button
          variant="link"
          isDisabled={!hasSignalStoreInitialized}
          textTransform="none"
          ml={2.5}
          fontWeight={300}
          color="kblue.400"
          fontSize="12px"
          onClick={onOpen}
        >
          edit all weights
        </Button>
        <Spacer />
        <Box color="kgray.300">
          {tags.length} Signal{tags.length !== 1 ? 's' : ''}
        </Box>
      </Flex>
      <Flex
        wrap="wrap"
        alignContent="flex-start"
        gap={2}
        minHeight={32}
        bgColor="kgray.50"
        borderRadius="lg"
        mb={8}
        padding={shouldShowDropBorder ? '13px' : '16px'}
        borderStyle="solid"
        borderWidth={shouldShowDropBorder ? '4px' : '1px'}
        borderColor={shouldShowDropBorder ? droppableBorderColor : 'kgray.200'}
        ref={drop}
      >
        {tags}
      </Flex>
    </Box>
  );
};
