import React, {useEffect, useRef, useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Box,
  ListItem,
  UnorderedList,
  Button,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import {
  BucketType,
  SignalWeights,
  useScoringModelSignalStore,
} from './SignalAssignmentStore';
import {SimpleSignal} from '../../signals';
import {entries} from '../../../shared/util';
import {ScoringSignal} from '../../../shared/signals';
import {Add, Subtract} from '@carbon/icons-react';
import {getScoringModelMaxPoints} from '../../../shared/scoring';
import {pluralize} from '../../../lib/helpers';
import {useCapability} from '../../../hooks/api/metadata';
import {useIsRescoring} from '../../../hooks/api/scoringInfo';
import {SignalWeightInput} from './SignalWeightInput';

const SignalListItem = ({
  signal,
  weight,
  allowZeroWeight,
}: {
  signal: ScoringSignal;
  weight: number;
  allowZeroWeight: boolean;
}) => {
  const {assignWeight, getServerFormat} = useScoringModelSignalStore();

  const maxPoints = getScoringModelMaxPoints(getServerFormat());
  const scorePercentage = weight / maxPoints;

  return (
    <ListItem py={2}>
      <Flex alignItems="center" justifyContent="flex-start" gap={2}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          border="1px solid"
          borderColor="#EBE8EA"
          borderRadius="10px"
          p={3}
          height="40px"
          flex="1"
        >
          <SimpleSignal signal={signal} key={signal} />
        </Flex>
        <Text fontSize="14" mt="2" fontWeight="thin" color="kgray.300">
          at
        </Text>

        <SignalWeightInput
          width="88px"
          min={allowZeroWeight ? 0 : 1}
          max={20}
          onWeightChange={(val) => {
            assignWeight(signal, val);
          }}
          weight={weight}
        />
        <Text
          mt="2"
          fontSize="14"
          fontWeight="thin"
          color="kgray.300"
          textAlign="right"
          w="200px"
        >
          (up to{' '}
          <strong>
            {Math.min(100, Math.round(scorePercentage * 1000) / 10)}%
          </strong>{' '}
          of total score)
        </Text>
      </Flex>
    </ListItem>
  );
};

const SignalsAccordionItem = ({
  signals,
  label,
  allowZeroWeight,
}: {
  signals: {[signal in ScoringSignal]?: number};
  label: string;
  allowZeroWeight: boolean;
}) => {
  const numSignals = Object.keys(signals).length;
  return (
    <AccordionItem isDisabled={numSignals === 0}>
      {({isExpanded}) => (
        <>
          <AccordionButton px={2}>
            <Flex my={6} width="100%">
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontSize="sm"
                textTransform="uppercase"
                color="#000"
              >
                {label}
              </Box>
              <Box
                as="span"
                fontSize="sm"
                textTransform="uppercase"
                color="kgray.300"
                mr={6}
              >
                {numSignals
                  ? pluralize(numSignals, 'signal', 'signals')
                  : 'no signals'}
              </Box>
              {isExpanded ? <Subtract size={20} /> : <Add size={20} />}
            </Flex>
          </AccordionButton>
          <AccordionPanel pb={4} pl={0} pt={0}>
            <UnorderedList listStyleType="none" marginLeft={0}>
              {entries(signals).map(([signal, weight]) => (
                <SignalListItem
                  key={signal}
                  signal={signal}
                  weight={weight!}
                  allowZeroWeight={allowZeroWeight}
                />
              ))}
            </UnorderedList>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export const SignalWeightsEditor = ({
  defaultBucket,
}: {
  defaultBucket: BucketType;
}) => {
  const {getSignalWeights} = useScoringModelSignalStore();

  const {positive, negative} = getSignalWeights();

  const allowZeroWeight = useCapability('zeroWeightSignals');

  return (
    <Accordion
      allowToggle
      overflowY="auto"
      defaultIndex={defaultBucket === 'positive' ? 0 : 1}
      mr={-2}
      pr={2}
      sx={{scrollbarGutter: 'stable'}}
    >
      <SignalsAccordionItem
        signals={positive}
        allowZeroWeight={allowZeroWeight}
        label={'Positive Signals'}
      />
      <SignalsAccordionItem
        signals={negative}
        allowZeroWeight={false}
        label={'Negative Signals'}
      />
    </Accordion>
  );
};

export const SignalWeightsModal = ({
  defaultBucket,
  isOpen,
  onClose,
}: {
  defaultBucket: BucketType;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const initialRef = useRef(null);
  const isResoring = useIsRescoring();

  const {
    clientWeights,
    hasWeightChanges,
    publishChanges: publishWeightChanges,
    resetWeightChanges,
  } = useScoringModelSignalStore();

  const [signalWeightsSnapshot, setSignalWeightsSnapshot] =
    useState<SignalWeights | null>(null);

  // Snapshot the client state so we can reset back to it if the user cancels
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setSignalWeightsSnapshot(clientWeights);

    // Only take a snapshot of the values when the modal is first opened.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onCancel = () => {
    onClose();

    if (!signalWeightsSnapshot) {
      return;
    }

    // Reset back to the snapshot
    resetWeightChanges(signalWeightsSnapshot);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onCancel}
      onOverlayClick={onCancel}
      size="6xl"
      isCentered
      scrollBehavior="inside"
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent height="100%">
        <Box ref={initialRef} tabIndex={-1} style={{opacity: 0}} />
        <ModalBody py={0}>
          <Flex height="100%">
            <Flex py={2} pr={8} flexGrow={1} flexDirection="column">
              <ModalHeader
                textAlign="left"
                fontSize="xl"
                fontWeight="500"
                pl={0}
              >
                Customize Signal Weights
                <Text fontSize="14" mt="2" fontWeight="thin" color="kgray.300">
                  Edit weights for multiple signals.
                </Text>
              </ModalHeader>
              <SignalWeightsEditor defaultBucket={defaultBucket} />
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter
          justifyContent="start"
          gap="2"
          borderTop="1px"
          borderColor="kgray.200"
        >
          <Tooltip
            isDisabled={!isResoring}
            label="Signal weight changes are disabled while rescoring is in progress."
            placement="top"
          >
            <Button
              colorScheme="kbuttonblue"
              isDisabled={
                // use the snapshots to determine if there are changes
                signalWeightsSnapshot !== null &&
                !hasWeightChanges(signalWeightsSnapshot)
              }
              isLoading={publishWeightChanges.isLoading}
              onClick={onClose}
            >
              Confirm
            </Button>
          </Tooltip>
          <Button colorScheme="kbuttonblue" variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
