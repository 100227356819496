import React from 'react';
import {Box, Flex, Divider, Tooltip, Button, useToast} from '@chakra-ui/react';
import {SamGuidanceMessage} from '../SamEditor/SamGuidanceMessage';
import {useOnboardingContext} from './Onboarding';
import {ScrollableFlex, WrapperFlex} from '../utils/scrolling';
import {useSamEditorStore} from '../SamEditor/useSamEditorStore';
import {useSamDefinitionLimits} from '../SamEditor/useSamDefinitionLimits';
import {SamEditor} from '../SamEditor/SamEditor';

export const SamFilter = () => {
  const {onPageNavigate, isFirstStep} = useOnboardingContext();
  const {isEmpty, query, publishChanges} = useSamEditorStore();
  const {isFetching: isFetchingSamLimits, withinLimits} =
    useSamDefinitionLimits();
  const toast = useToast();

  const onNext = () => {
    publishChanges.mutate(undefined, {
      onSuccess: () => {
        onPageNavigate('next');
      },

      onError: () => {
        toast({
          title: 'Error',
          description: 'Failed to save SAM filters',
          status: 'error',
          duration: 5_000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <WrapperFlex>
      <ScrollableFlex>
        <Box fontSize="2xl" mb={5}>
          First, set some filters for your serviceable market
        </Box>
        <Box color="kgray.300" mb={10}>
          What kind of companies can you serve? This sets your boundaries.
        </Box>
        <Flex gap={4} mb={10} grow={1}>
          <SamEditor />
        </Flex>
      </ScrollableFlex>

      <Divider />

      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={10}
        px={10}
        py={4}
      >
        {!isFirstStep && (
          <Button
            colorScheme="kbuttonblue"
            onClick={() => onPageNavigate('prev')}
            variant="outline"
            w="110px"
          >
            Back
          </Button>
        )}
        <SamGuidanceMessage
          borderRadius={5}
          defaultTransparentBg={true}
          flexGrow="1"
        />
        <Tooltip
          isDisabled={!isEmpty() && withinLimits}
          label="Edit your filters to continue"
          placement="top-start"
        >
          <Button
            colorScheme="kbuttonblue"
            isDisabled={!query.isSuccess || isEmpty() || !withinLimits}
            isLoading={publishChanges.isLoading || isFetchingSamLimits}
            onClick={onNext}
            variant="solid"
            w="110px"
          >
            Continue
          </Button>
        </Tooltip>
      </Flex>
    </WrapperFlex>
  );
};
