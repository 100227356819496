import {z} from 'zod';
import {ApiDefinition} from './definitions';
import {CustomerIntegrationSchema} from '../customer';

export const DisconnectIntegration = {
  method: 'post',
  requestDataSchema: CustomerIntegrationSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const DeleteIntegration = {
  method: 'post',
  requestDataSchema: CustomerIntegrationSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;
