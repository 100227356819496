import {ObjectId} from 'bson';
import {useMetadata} from '../../context/MetadataContext';
import {Capability, Toggle, isCapabilityEnabled} from '../../shared/customer';

export function useCustomer() {
  const metadata = useMetadata();
  return metadata.customer;
}

export function useIsFreemiumCustomer() {
  const customer = useCustomer();
  return customer.plan.type === 'free';
}

export function useIsListBuilderCustomer() {
  const customer = useCustomer();
  return customer.plan.type === 'listBuilder';
}

export function useIsFreeOrListBuilderCustomer() {
  const customer = useCustomer();
  return customer.plan.type === 'free' || customer.plan.type === 'listBuilder';
}

export function useIsToggleEnabled(toggle: Toggle) {
  const customer = useCustomer();
  return customer.toggles?.includes(toggle) ?? false;
}

export function useFeatures() {
  const customer = useCustomer();
  return customer.features ?? [];
}

export function useCapability(capability: Capability) {
  const customer = useCustomer();
  return isCapabilityEnabled(customer, capability);
}

// The max height calculation takes into account the space occupied by everything above the table
// e.g. the main app header (50px), the sub-tab section (140px for freemium, 55px otherwise), and the filters (71px)
export function useContentPaneHeight() {
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  return `calc(100vh - ${isFreeOrListBuilderCustomer ? 261 : 176}px)`;
}

export function useDefaultMarketId() {
  const metadata = useMetadata();
  return metadata.defaultMarketId;
}

export function useMarkets() {
  const metadata = useMetadata();
  return metadata.markets;
}

export function useMarket(marketId: ObjectId) {
  const market = useMarkets().find((market) => market.id.equals(marketId));
  if (!market) {
    throw new Error(`Market not found: ${marketId}`);
  }
  return market;
}

// Returns default market ID if the specified ID isn't valid, or doesn't
// correspond to an existing market.
export function useVerifiedMarketId(rawMarketId: string | undefined) {
  const markets = useMarkets();
  const defaultMarketId = useDefaultMarketId();

  if (!rawMarketId) {
    return defaultMarketId;
  }

  try {
    const marketId = new ObjectId(rawMarketId);
    if (markets.find((market) => market.id.equals(marketId))) {
      return marketId;
    }
    return defaultMarketId;
  } catch {
    return defaultMarketId;
  }
}
