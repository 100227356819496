import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {ApiDefinition} from '../../shared/api/definitions';
import {z} from 'zod';
import {captureMessage} from '@sentry/react';

// Factory function to create a query hook for a given API endpoint.
export function createApiQuery<Definition extends ApiDefinition>(
  path: string,
  definition: Definition,
  queryKeyFn: (
    args: z.infer<Definition['requestDataSchema']>
  ) => readonly unknown[],
  defaultOptions?: UseQueryOptions<
    z.infer<Definition['responseDataSchema']>
  > & {
    toastOnError?: boolean;
  }
) {
  return (
    params: z.infer<Definition['requestDataSchema']>,
    options?: UseQueryOptions<z.infer<Definition['responseDataSchema']>>
  ) => {
    const makeApiCall = useKeyplayApi();
    return useQuery<z.infer<Definition['responseDataSchema']>>(
      queryKeyFn(params),
      async () => {
        const result = await makeApiCall<Definition['responseDataSchema']>(
          path,
          {
            method: definition.method,
            params,
          },
          {toastOnError: defaultOptions?.toastOnError}
        );

        const safeParse = definition.responseDataSchema.safeParse(result);
        if (!safeParse.success) {
          captureMessage(safeParse.error.message, 'error');
          throw new Error('Invalid response');
        }

        return result;
      },
      {
        ...defaultOptions,
        ...options,
      }
    );
  };
}

export function useApiMutation<Definition extends ApiDefinition>(
  path: string,
  definition: Definition,
  defaultOptions?: UseMutationOptions<
    z.infer<Definition['responseDataSchema']>,
    unknown, // Error type
    z.infer<Definition['requestDataSchema']>
  > & {
    disableToastOnError?: boolean;
  }
) {
  const makeApiCall = useKeyplayApi();
  return useMutation<
    z.infer<Definition['responseDataSchema']>,
    unknown, // Error type
    z.infer<Definition['requestDataSchema']>
  >({
    mutationFn: async (args: z.infer<Definition['requestDataSchema']>) => {
      const result = await makeApiCall<
        z.infer<Definition['requestDataSchema']>
      >(
        path,
        {
          method: definition.method,
          data: args,
        },
        {toastOnError: !defaultOptions?.disableToastOnError}
      );

      const safeParse = definition.responseDataSchema.safeParse(result);
      if (!safeParse.success) {
        captureMessage(safeParse.error.message, 'error');
        throw new Error('Invalid response');
      }

      return result;
    },
    ...defaultOptions,
  });
}
