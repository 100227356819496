import {useQuery, useQueryClient} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {SamDefinition} from '../../shared/market';
import {SamDefinitionSizeResponse} from '../../shared/api/api';
import {
  GetSamDefinition,
  UpdateSamDefinition,
} from '../../shared/api/definitions';
import {createApiQuery, useApiMutation} from './api';
import {ScoringInfoQueryKey} from './scoringInfo';

export const SamDefinitionCacheKey = 'samDefinition';
export const SamDefinitionKeys = {
  all: [SamDefinitionCacheKey],
  market: (marketId: string) => [...SamDefinitionKeys.all, marketId],
} as const;

export const useSamDefinition = createApiQuery(
  '/samDefinition',
  GetSamDefinition,
  ({marketId}) => SamDefinitionKeys.market(marketId)
);

export const useSamDefinitionMutation = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/samDefinition', UpdateSamDefinition, {
    onSuccess: async () => {
      // Refetch list of tasks
      await queryClient.invalidateQueries(SamDefinitionKeys.all);

      // refreshing sam might trigger an account refresh
      await queryClient.invalidateQueries([ScoringInfoQueryKey]);
    },
  });
};

export function useSamDefinitionSize({
  enabled,
  onSuccess,
  samDefinition,
}: {
  enabled: boolean;
  onSuccess?: (data: SamDefinitionSizeResponse) => void;
  samDefinition: SamDefinition;
}) {
  const keyplayApi = useKeyplayApi();
  return useQuery(
    ['samDefinitionSize', JSON.stringify(samDefinition)],
    () =>
      keyplayApi<SamDefinitionSizeResponse>(
        `/samDefinitionSize?sam=${JSON.stringify(samDefinition)}`,
        {
          method: 'GET',
        },
        {toastOnError: true}
      ),
    {
      enabled,
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );
}
