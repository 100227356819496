import React, {createContext, useContext} from 'react';
import {ObjectId} from 'bson';
import {useMarket} from '../../hooks/api/metadata';

const MarketIdContext = createContext<{
  marketId: ObjectId;
} | null>(null);

export const MarketIdProvider = ({
  children,
  marketId,
}: React.PropsWithChildren<{marketId: ObjectId}>) => {
  const context = {marketId};

  return (
    <MarketIdContext.Provider value={context}>
      {children}
    </MarketIdContext.Provider>
  );
};

export const useMarketId = () => {
  const provider = useContext(MarketIdContext);
  if (!provider) {
    throw new Error('MarketIdContext not found');
  }

  return provider.marketId;
};

export const useMarketFromContext = () => {
  const marketId = useMarketId();
  return useMarket(marketId);
};
