import React, {useEffect, useState} from 'react';
import {useAuth0, User} from '@auth0/auth0-react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {Box, Button, Flex, Text} from '@chakra-ui/react';
import {Header} from './AppHeader';
import {useQuery} from '@tanstack/react-query';
import {KeyplayApiProvider, useKeyplayApi} from '../../context/ApiContext';
import {LoadingScreen} from './LoadingScreen';
import MyAccounts from '../Accounts';
import Admin from './Admin';
import {ApiAudience} from '../../shared/auth';
import Download from '../Download/Download';
import * as Sentry from '@sentry/react';
import {isKeyplayAdmin} from '../../lib/user';
import Settings from '../Settings';
import Discover from '../Discover';
import {Onboarding} from '../Onboarding/Onboarding';
import {Welcome} from './Welcome';
import {analytics} from '../../analytics';
import {useNavigate} from 'react-router-dom';
import {UserProvider, useUser} from '../../context/UserContext';
import {
  MetadataProvider,
  useMetadata,
  useMetadataQuery,
} from '../../context/MetadataContext';
import {registerSuperJsonTypes} from '../../shared/api/superjson';
import {useIsTemporaryMembership} from '../../hooks/api/user';
import {useCustomer} from '../../hooks/api/metadata';
import {Bold} from '../text';
import {useRemoveUserMutation} from '../Debug/hooks/customer';
import {ErrorPage} from './ErrorPage';
import {Markets} from '../Market/Markets';

registerSuperJsonTypes();

function AppWrapper() {
  const {
    user,
    isAuthenticated,
    isLoading,
    logout,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  // https://auth0.com/docs/libraries/auth0-react

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      let token = null;
      const tokenOptions = {audience: ApiAudience};

      try {
        token = await getAccessTokenSilently(tokenOptions);
      } catch (e) {
        console.error(e);
      }

      // https://github.com/auth0/auth0-react/issues/65
      if (!token) {
        try {
          token = await getAccessTokenWithPopup(tokenOptions);
        } catch (e) {
          console.error(e);
        }
      }

      setToken(token);
    })();
  }, [user, getAccessTokenSilently, getAccessTokenWithPopup]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" replace={true} />;
  }

  if (!token) {
    return <LoadingScreen />;
  }

  return (
    <KeyplayApiProvider token={token}>
      <AuthenticatedApp
        user={user}
        logout={logout}
        onTokenUpdated={setToken}
        token={token}
      />
    </KeyplayApiProvider>
  );
}

const AuthenticatedApp = ({
  user,
  onTokenUpdated,
  token,
  logout,
}: {
  user: User;
  onTokenUpdated: (token: string) => void;
  token: string;
  logout: Function;
}) => {
  const keyplayApi = useKeyplayApi();
  Sentry.setUser({
    email: user.email,
  });

  // Although the user is now authenticated (i.e. logged in), we need to check to see if they are
  // authorized to view the app in the context of a customer.
  const {data, isError, isLoading, refetch} = useQuery(['authorized'], () =>
    keyplayApi<{authed: boolean}>('/customer/authorized')
  );

  useEffect(() => {
    refetch();
  }, [refetch, token]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      {!data?.authed ? (
        // The user is authenticated, but not authorized to access the app
        // because either 1) they are not a member of any customer or 2) they
        // need to select which customer they want to access.
        <UnauthorizedApp
          logout={logout}
          onTokenUpdated={onTokenUpdated}
          user={user}
        />
      ) : (
        // The user is authenticated and authorized to access the app for a
        // specific customer.
        <UserProvider auth0User={user}>
          <AuthorizedApp token={token} user={user} />
        </UserProvider>
      )}
    </>
  );
};

const AuthorizedApp = ({token, user}: {token: string; user: User}) => {
  const metadata = useMetadataQuery();

  if (metadata.isLoading) {
    return <LoadingScreen />;
  }

  if (!metadata.data && metadata.isError) {
    return <ErrorPage />;
  }

  const {customer} = metadata.data;
  Sentry.setTags({
    'customer.id': customer._id.toString(),
    'customer.name': customer.name,
  });

  const isFreemiumCustomer = customer.plan.type === 'free';

  const appRoutes = isFreemiumCustomer ? (
    <>
      <Route path="/accounts/*" element={<MyAccounts />} />
      <Route path="/discover/*" element={<Discover />} />
      <Route path="/download" element={<Download />} />
      <Route path="/markets/*" element={<Markets />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="*" element={<Navigate to="/discover/recommended" />} />
    </>
  ) : (
    <>
      <Route path="/accounts/*" element={<MyAccounts />} />
      <Route path="/discover/*" element={<Discover />} />
      <Route path="/markets/*" element={<Markets />} />
      <Route path="/download" element={<Download />} />
      <Route path="/settings/*" element={<Settings />} />
      <Route path="*" element={<Navigate to="/discover/recommended" />} />
    </>
  );

  return (
    <MetadataProvider metadata={metadata.data}>
      <Box className="App" h="100%">
        {!customer.onboarded ? (
          <Routes>
            <Route path="/onboarding/*" element={<Onboarding />} />
            <Route path="*" element={<Navigate to="/onboarding" />} />
          </Routes>
        ) : (
          <Flex direction="column" h="100%">
            <Header token={token} />
            <Box pt="50px" flexGrow="1">
              <Routes>
                {appRoutes}
                {isKeyplayAdmin(user) && (
                  <Route path="/admin/*" element={<Admin />} />
                )}
              </Routes>
            </Box>
          </Flex>
        )}
        <TemporaryMembershipBanner />
        <Analytics />
      </Box>
    </MetadataProvider>
  );
};

const TemporaryMembershipBanner = () => {
  const user = useUser();
  const customer = useCustomer();
  const removeUser = useRemoveUserMutation();

  const isTemporaryMembership = useIsTemporaryMembership();
  if (!isTemporaryMembership) {
    return null;
  }

  return (
    <Flex
      bgColor="kred.300"
      color="kgray.200"
      justifyContent="space-between"
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      p="2"
      zIndex="2"
    >
      <Text>
        You are temporarily logged into <Bold>{customer.name}</Bold>.
      </Text>
      <Button
        colorScheme="white"
        isDisabled={removeUser.isLoading}
        onClick={() => {
          removeUser.mutate(
            {
              userId: user.auth0.sub!,
              customerId: customer._id,
            },
            {
              onSuccess: () => {
                window.location.reload();
              },
            }
          );
        }}
        variant="link"
      >
        Remove access
      </Button>
    </Flex>
  );
};

const UnauthorizedApp = ({
  logout,
  onTokenUpdated,
  user,
}: {
  logout: Function;
  onTokenUpdated: (token: string) => void;
  user: User;
}) => {
  const nav = useNavigate();

  return (
    <Routes>
      <Route
        path="/welcome"
        element={
          <Welcome
            logout={logout}
            onTokenUpdated={(token) => {
              onTokenUpdated(token);
              nav('/accounts/all');
            }}
            user={user}
          />
        }
      />
      <Route path="*" element={<Navigate to="/welcome" />} />
    </Routes>
  );
};

// Dummy component for analytics tracking to reduce app re-renders.
const Analytics = () => {
  const metadata = useMetadata();
  const user = useUser();
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Only call identify when we have all the fields to track.
    if (!metadata.customer || !user.extras.intercomHash) {
      return;
    }

    const {_id, name, plan} = metadata.customer;
    analytics.identify(
      user.auth0.sub,
      {
        email: user.auth0.email,
        name: user.auth0.name,
        company: {
          id: _id.toString(),
          name,
          plan: plan.type,
        },
        lastLogin: new Date().getTime(),
      },
      {
        integrations: {
          Intercom: {
            user_hash: user.extras.intercomHash,
          },
        },
      }
    );
    setInitialized(true);
  }, [metadata.customer, user]);

  useEffect(() => {
    // Wait until we have identified the user before tracking page views.
    if (!initialized) {
      return;
    }

    analytics.page();
  }, [location, initialized]);

  return <></>;
};

export default AppWrapper;
