import {useQueryClient} from '@tanstack/react-query';
import {useApiMutation} from './api';
import {SetOnboardingTemplate} from '../../shared/api/definitions';
import {ScoringModelCacheKey} from './scoringModel';
import {SamDefinitionCacheKey} from './samDefinition';

export const useSetOnboardingTemplate = () => {
  const queryClient = useQueryClient();

  return useApiMutation('/setOnboardingTemplate', SetOnboardingTemplate, {
    onSuccess: async () => {
      // Refetch list of tasks
      await queryClient.invalidateQueries([
        ScoringModelCacheKey,
        SamDefinitionCacheKey,
      ]);
    },
  });
};
