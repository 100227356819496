import React from 'react';
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  Tabs,
  Link as ChakraLink,
  useDisclosure,
} from '@chakra-ui/react';
import {Link, Navigate, Route, Routes, useMatch} from 'react-router-dom';
import AccountGrid from '../AccountGrid';
import {TabbedPage} from '../TabbedPage';
import {
  useCustomer,
  useFeatures,
  useIsFreeOrListBuilderCustomer,
  useIsListBuilderCustomer,
  useMarkets,
} from '../../hooks/api/metadata';
import _ from 'lodash';
import {getSavesQuota} from '../../shared/customer';
import {useIntegrations} from '../../hooks/api/integrations';
import {CreditsPurchaseModal} from '../App/CreditUsage';

const MyAccounts = () => {
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  return isFreeOrListBuilderCustomer ? (
    <ListBuilderMyAccounts />
  ) : (
    <PaidMyAccounts />
  );
};

const ListBuilderMyAccounts = () => {
  const isListBuilder = useIsListBuilderCustomer();
  const {data, isLoading, isError} = useIntegrations();
  const customer = useCustomer();
  const {current} = getSavesQuota(customer);
  const creditsModal = useDisclosure();
  const markets = useMarkets();

  const integrationsLoading = isLoading || isError;
  const hubspotConnected = data?.hubspot?.status === 'active';

  return (
    <Flex direction="column">
      <Flex
        alignItems="baseline"
        bgColor="kgray.100"
        height="140px"
        gap={2}
        px={5}
        py={8}
        justifyContent="space-between"
      >
        <Box>
          <Box fontSize="2xl" mb={2}>
            You&apos;re tracking {current} saved accounts
          </Box>
          <Box color="kgray.300" display={{base: 'none', lg: 'block'}}>
            Filter & export your accounts here.{' '}
            <ChakraLink color="kblue.300" onClick={creditsModal.onOpen}>
              Get additional credits to expand
            </ChakraLink>
            .
          </Box>
        </Box>
        <Flex gap="2" direction={{base: 'column', lg: 'row'}}>
          {isListBuilder && !integrationsLoading && !hubspotConnected && (
            <Button
              as={Link}
              colorScheme="kbuttonblue"
              flexShrink="0"
              fontWeight="normal"
              to="/settings/integrations"
              variant="outline"
            >
              Connect HubSpot
            </Button>
          )}
          <Button
            as={Link}
            colorScheme="kbuttonblue"
            flexShrink="0"
            fontWeight="normal"
            to={`/markets/${markets[0].id}`}
          >
            View & Adjust Scoring
          </Button>
        </Flex>
        <CreditsPurchaseModal
          isOpen={creditsModal.isOpen}
          onClose={creditsModal.onClose}
        />
      </Flex>
      <Routes>
        <Route path="saved" element={<AccountGrid gridView="saved" />} />
        <Route path="*" element={<Navigate to="/accounts/saved" />} />
      </Routes>
    </Flex>
  );
};

const PaidMyAccounts = () => {
  const isAllTab = useMatch('/accounts/all');
  const isCrmTab = useMatch('/accounts/crm');
  const isSavedTab = useMatch('/accounts/saved');
  const isImportedTab = useMatch('/accounts/imported');

  const showImportsTab = useFeatures().includes('importsTab');

  return (
    <TabbedPage title="Accounts">
      <Tabs
        variant="line"
        index={_.findIndex(
          [isAllTab, isCrmTab, isSavedTab, isImportedTab],
          (x) => !!x
        )}
      >
        <TabList>
          <Tab as={Link} to="all" ml={24}>
            All
          </Tab>
          <Tab as={Link} to="crm">
            CRM
          </Tab>
          <Tab as={Link} to="saved">
            Saved
          </Tab>
          {showImportsTab && (
            <Tab as={Link} to="imported">
              Imported
            </Tab>
          )}
        </TabList>

        <Routes>
          <Route path="all" element={<AccountGrid gridView="all" />} />
          {showImportsTab && (
            <Route
              path="imported"
              element={<AccountGrid gridView="imported" />}
            />
          )}
          <Route path="saved" element={<AccountGrid gridView="saved" />} />
          <Route path="crm" element={<AccountGrid gridView="crm" />} />
          <Route path="*" element={<Navigate to="/accounts/all" />} />
        </Routes>
      </Tabs>
    </TabbedPage>
  );
};

export default MyAccounts;
