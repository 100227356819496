import {ObjectId} from 'bson';
import {
  BusinessAudienceSchema,
  PrimaryBusinessCategorySchema,
  SecondaryBusinessCategorySchema,
} from './account';
import {CountryCodeSchema} from './countries';
import {
  ScoringCategories,
  KeyplayScoringSignal,
  KeyplayScoringSignalSchema,
  StackFitSignalSchema,
  ScoringSignalSchema,
  ScoringSignal,
} from './signals';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {fromEntries} from './util';
import {Customer} from './customer';
import _ from 'lodash';

// Get all signal groups in the provided sam (including tech signals).
export function getSamSignalGroups(
  samDefinition: SamDefinition
): KeyplayScoringSignal[][] {
  return [
    samDefinition.stackFitSignals ?? [],
    ...(samDefinition.matchGroups ?? []),
  ].filter((group) => group.length);
}

// Get a deduped list of all signals in the provided sam.
export function getSamSignals(
  samDefinition: SamDefinition
): KeyplayScoringSignal[] {
  const signals = getSamSignalGroups(samDefinition).flat();
  return [...new Set(signals)];
}

export function getScoringModelSignals(
  signalsWithWeights: SignalsWithWeights
): ScoringSignal[] {
  return signalsWithWeights.map(({signal}) => signal);
}

// Get a deduped list of all signals (SAM and scoring model) for the customer.
export function getAllCustomerSignals(customer: Customer) {
  return _.uniq(customer.markets.flatMap(getSignalsInMarket));
}

export function getSignalsInMarket(market: Market) {
  const samSignals = getSamSignals(market.samDefinition);
  const scoringModelSignals = getScoringModelSignals(
    market.scoringModel.signalsWithWeights
  );

  return _.uniq([...samSignals, ...scoringModelSignals]);
}

export const SamDefinitionSchema = z.object({
  primaryCategory: z.optional(z.array(PrimaryBusinessCategorySchema)),
  secondaryCategory: z.optional(z.array(SecondaryBusinessCategorySchema)),
  audience: z.optional(z.array(BusinessAudienceSchema)),
  employees: z.optional(
    z.object({
      min: z.optional(z.number()),
      max: z.optional(z.number()),
    })
  ),
  locations: z.optional(z.array(CountryCodeSchema)),
  hqCountries: z.optional(z.array(CountryCodeSchema)),
  stackFitSignals: z.optional(z.array(StackFitSignalSchema)),
  matchGroups: z.optional(z.array(z.array(KeyplayScoringSignalSchema))),

  excludes: z
    .object({
      primaryCategory: z.array(PrimaryBusinessCategorySchema).optional(),
      secondaryCategory: z.array(SecondaryBusinessCategorySchema).optional(),
    })
    .optional(),
});
export type SamDefinition = z.infer<typeof SamDefinitionSchema>;
export const isSamDefinition = zodTypeguard(SamDefinitionSchema);

export const ScoringModelWeights = z.object(
  fromEntries(
    ScoringCategories.map((category) => [
      category,
      z.object({
        percentage: z.number(),
        maxPoints: z.number().nullable(),
      }),
    ])
  )
);

const SignalWithWeightSchema = z.object({
  signal: ScoringSignalSchema,
  weight: z.number(),
});
export type SignalWithWeight = z.infer<typeof SignalWithWeightSchema>;

const SignalsWithWeightsSchema = z.array(SignalWithWeightSchema);
export type SignalsWithWeights = z.infer<typeof SignalsWithWeightsSchema>;

const ScoringModeBaseSchema = z.object({
  type: z.string(),
});

const HighestValue = ScoringModeBaseSchema.extend({
  type: z.literal('highest_value'),
});

const SignalScorePlusBonusPoints = ScoringModeBaseSchema.extend({
  type: z.literal('signal_score_plus_bonus_points'),
});

const SimilarityPlusBonusPoints = ScoringModeBaseSchema.extend({
  type: z.literal('similarity_plus_bonus_points'),
});

const OnlySignalScore = ScoringModeBaseSchema.extend({
  type: z.literal('only_signal_score'),
});

const OnlySimilarity = ScoringModeBaseSchema.extend({
  type: z.literal('only_similarity'),
});

const ScoringModeSchema = z.union([
  HighestValue,
  SignalScorePlusBonusPoints,
  SimilarityPlusBonusPoints,
  OnlySignalScore,
  OnlySimilarity,
]);
export type ScoringMode = z.infer<typeof ScoringModeSchema>;

export const ScoringModelSchema = z.object({
  signalsWithWeights: SignalsWithWeightsSchema,
  scoringMode: ScoringModeSchema,
  lookalikes: z.optional(z.array(z.instanceof(ObjectId))),
});
export type ScoringModel = z.infer<typeof ScoringModelSchema>;
export const isScoringModel = zodTypeguard(ScoringModelSchema);

export const EmptySamDefinition: SamDefinition = {};

export const DefaultSamDefinition: SamDefinition = {
  employees: {
    min: 100,
    max: 1000,
  },
  locations: ['US'],
};

export const MarketSchema = z.object({
  id: z.instanceof(ObjectId),
  label: z.string(),

  samDefinition: SamDefinitionSchema,
  scoringModel: ScoringModelSchema,
});
export type Market = z.infer<typeof MarketSchema>;

export const SimilarAccountSizeRequest = z.object({
  lookalikeIds: z.array(z.string()),
});
export type SimilarAccountSizeRequest = z.infer<
  typeof SimilarAccountSizeRequest
>;
export type SimilarAccountSizeResponse = {
  size: number;
};
