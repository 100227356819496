import {useQuery} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {AccountSimilarityScoreResponse} from '../../shared/api/responses';
import {ObjectId} from 'bson';

export function useAccountSimilarityScore({
  accountId,
  lookalikeIds,
}: {
  accountId?: ObjectId;
  lookalikeIds?: ObjectId[];
}) {
  const keyplayApi = useKeyplayApi();

  return useQuery<AccountSimilarityScoreResponse>(
    ['accountSimilarityScore', accountId, lookalikeIds],
    () =>
      keyplayApi('/accountSimilarityScore', {
        params: {accountId, lookalikeIds},
        method: 'GET',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!accountId && !!lookalikeIds?.length,
    }
  );
}
