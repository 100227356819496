import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Checkbox,
  Flex,
  chakra,
  Spinner,
  Center,
  Divider,
  Link,
} from '@chakra-ui/react';

import {flexRender, Table as TableType} from '@tanstack/react-table';

import {useVirtualizer} from '@tanstack/react-virtual';
import {useCallback} from 'react';
import _ from 'lodash';
import {DataTable} from '@carbon/icons-react';
import {ScoredAccount} from '../../shared/scoredAccounts';
import {useAccountView, useScoringRun} from './AccountGrid';
import {
  useContentPaneHeight,
  useCustomer,
  useIsFreemiumCustomer,
  useMarkets,
} from '../../hooks/api/metadata';
import {Link as ReactRouterLink} from 'react-router-dom';
import {useEnabledIntegration} from '../../hooks/api/integrations';
import {useIsRescoring} from '../../hooks/api/scoringInfo';
import {ObjectId} from 'bson';
import {useUserControls} from './AccountGrid.controlstate';
import {getLimit, getSavesQuota} from '../../shared/customer';
import {AccountActionQueryLimit} from '../../shared/api/api';

interface AccountsTableProps {
  table: TableType<ScoredAccount>;
  fetchedAccounts: ScoredAccount[];
  totalFetched: number;
  numberOfAccounts: number | undefined;
  isFetching: boolean;
  // true if, say, the user used the table controls to get new data and we're
  // currently fetching it...we should show some kind of loading indicator.
  isFetchingDifferentQuery: boolean;
  onOpenDetails: (accountId: ObjectId) => void;
  fetchNextPage: Function;
}

export function AccountsTable({
  table,
  fetchedAccounts,
  totalFetched,
  numberOfAccounts,
  isFetching,
  isFetchingDifferentQuery,
  onOpenDetails,
  fetchNextPage,
}: AccountsTableProps) {
  const accountView = useAccountView();
  const {numBulkSelected, setNumBulkSelected} = useUserControls();

  // scrolling element for virtualization
  const scrollingElemRef = React.useRef<HTMLDivElement>(null);

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
        // once the user has scrolled within some distance of the bottom of the table,
        // fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 800 &&
          !isFetching &&
          totalFetched < (numberOfAccounts ?? 0)
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, numberOfAccounts]
  );

  const customer = useCustomer();
  const {remaining: remainingSaves} = getSavesQuota(customer);
  const activeAccountsLimit = getLimit(customer, 'activeAccounts');

  const maxSelectable = Math.min(
    ...(accountView === 'recommended'
      ? // For Discover, make sure users can't select more than their remaining saves or
        // active accounts limit.
        [remainingSaves, activeAccountsLimit]
      : []),
    numberOfAccounts ?? 0,
    AccountActionQueryLimit
  );

  // a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(scrollingElemRef.current);
  }, [fetchMoreOnBottomReached]);

  const virtualizer = useVirtualizer({
    count: fetchedAccounts.length,
    getScrollElement: () => scrollingElemRef.current,
    estimateSize: () => 80,
    overscan: 10,
  });
  const virtualRows = virtualizer.getVirtualItems();
  const totalSize = virtualizer.getTotalSize();

  const isRescoring = useIsRescoring();
  // computations from virtualizer to properly position table
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const enabledIntegration = useEnabledIntegration();
  const additionalBottomPadding =
    (!!enabledIntegration && accountView === 'saved') || isRescoring;

  const maxHeight = useContentPaneHeight();
  return (
    <Box
      fontSize={14}
      maxWidth="100vw"
      height={maxHeight}
      overflow={fetchedAccounts.length ? 'scroll' : 'hidden'}
      onScroll={_.throttle(
        (e) => fetchMoreOnBottomReached(e.target as HTMLDivElement),
        50
      )}
      ref={scrollingElemRef}
      opacity={isFetchingDifferentQuery ? 0.5 : 1}
      borderTop="1px solid #E8E8EB"
      pb={additionalBottomPadding ? '85px' : ''}
    >
      <Table sx={{tableLayout: 'fixed'}}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const meta: any = header.column.columnDef.meta;

                if (header.column.columnDef.id === 'select') {
                  return (
                    <Th
                      key={header.id}
                      width={meta?.width ? `${meta.width}px` : undefined}
                      bg="white"
                      whiteSpace="nowrap"
                      position="sticky"
                      p={0}
                      top={0}
                      zIndex={1}
                    >
                      <Box px={3} py={2} borderBottom="1px solid #E8E8EB">
                        <Checkbox
                          isChecked={!!numBulkSelected}
                          isDisabled={!maxSelectable}
                          onChange={(e) =>
                            setNumBulkSelected(
                              e.target.checked ? maxSelectable : 0
                            )
                          }
                        />
                      </Box>
                    </Th>
                  );
                }

                return (
                  <Th
                    key={header.id}
                    bg="white"
                    borderRight="1px solid #E8E8EB"
                    whiteSpace="nowrap"
                    position="sticky"
                    p={0}
                    top={0}
                    width={meta?.width ? `${meta.width}px` : undefined}
                    zIndex={1}
                  >
                    <Box p={2} borderBottom="1px solid #E8E8EB">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{height: `${paddingTop}px`}} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = table.getRowModel().rows[virtualRow.index];
            return (
              <Tr key={row.id} maxHeight="80px" className={row.id} role="group">
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const meta: any = cell.column.columnDef.meta;

                  if (cell.column.columnDef.id === 'select') {
                    return (
                      <Td
                        key={cell.id}
                        verticalAlign="top"
                        width={meta?.width ? `${meta.width}px` : undefined}
                        p={0}
                        _groupHover={{bg: 'kgray.100'}}
                      >
                        <Checkbox
                          width="100%"
                          height="80px"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="start"
                          pt={2}
                          isChecked={
                            virtualRow.index < numBulkSelected ||
                            row.getIsSelected()
                          }
                          isDisabled={!!numBulkSelected}
                          isIndeterminate={row.getIsSomeSelected()}
                          onChange={row.getToggleSelectedHandler()}
                        />
                      </Td>
                    );
                  }
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      verticalAlign="top"
                      borderRight="1px solid #E8E8EB"
                      p={2}
                      cursor={meta?.doesntOpenDetails ? undefined : 'pointer'}
                      _groupHover={{bg: 'kgray.100'}}
                      onClick={
                        meta?.doesntOpenDetails
                          ? undefined
                          : () => onOpenDetails(row.original.accountId)
                      }
                    >
                      <Box height="64px" overflowY="hidden">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Box>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
          {paddingBottom > 0 && (
            <tr>
              <td style={{height: `${paddingBottom}px`}} />
            </tr>
          )}
        </Tbody>
      </Table>
      {!fetchedAccounts.length && <EmptyTable />}
    </Box>
  );
}

const ChakraDataTable = chakra(DataTable);

const EmptyTable = () => {
  const scoringRun = useScoringRun();
  const isFreemiumCustomer = useIsFreemiumCustomer();
  const accountView = useAccountView();
  const {filtersDifferFromDefault} = useUserControls();

  if (!isFreemiumCustomer) {
    return <DefaultNoAccountsMessage />;
  }

  let emptyTableContents;
  if (accountView === 'recommended') {
    if (!scoringRun?._id) {
      emptyTableContents = <FreemiumInitialLoadingMessage />;
    } else {
      emptyTableContents = <FreemiumDiscoverNoAcountsMessage />;
    }
  } else {
    if (!scoringRun?.numActive && !filtersDifferFromDefault()) {
      emptyTableContents = <FreemiumMyAccountsMessage />;
    } else {
      emptyTableContents = <DefaultNoAccountsMessage />;
    }
  }

  return (
    <Flex direction="column" alignItems="center" py="32">
      {emptyTableContents}
    </Flex>
  );
};

const DefaultNoAccountsMessage = () => {
  return (
    <Flex direction="column" alignItems="center" py="32">
      <ChakraDataTable color="kgray.400" width={32} height={32} />
      <Box fontSize={16} mt={8}>
        There are no accounts that match the selected filters.
      </Box>
    </Flex>
  );
};

const FreemiumInitialLoadingMessage = () => {
  return (
    <Flex direction="column" alignItems="center" py="32">
      <Spinner color="kred.300" size="lg" mb={4} />
      <Box fontSize="lg" mb={2}>
        Building your list.
      </Box>
      <Box color="kgray.300">
        If this takes more than a few minutes, try refreshing the page.
      </Box>
    </Flex>
  );
};

const FreemiumDiscoverNoAcountsMessage = () => {
  const markets = useMarkets();
  return (
    <>
      <ChakraDataTable color="kgray.400" width={32} height={32} />
      <Box fontSize={16} mt={8} px={4} textAlign="center">
        <Box>
          There are no accounts found for your given serviceable market and
          lookalike list.
        </Box>
        <Box>
          Adjust your{' '}
          <Link
            as={ReactRouterLink}
            textDecoration="underline"
            to={`/markets/${markets[0].id}/define`}
          >
            serviceable market
          </Link>{' '}
          or{' '}
          <Link
            as={ReactRouterLink}
            style={{textDecoration: 'underline'}}
            to={`/markets/${markets[0].id}`}
          >
            lookalike list
          </Link>{' '}
          to discover new accounts.
        </Box>
      </Box>
    </>
  );
};

const FreemiumEmptyStateSection = ({
  step,
  title,
  description,
}: {
  step: number;
  title: string;
  description: React.ReactNode;
}) => {
  return (
    <Flex direction="column" alignItems="center" gap="4" w="180px">
      <Center
        bgColor="kgray.100"
        borderRadius="full"
        fontSize="2xl"
        fontWeight="500"
        h="64px"
        w="64px"
      >
        {step}
      </Center>
      <Box fontSize="lg" fontWeight="500">
        {title}
      </Box>
      <Box color="kgray.300" textAlign="center">
        {description}
      </Box>
    </Flex>
  );
};

const FreemiumMyAccountsMessage = () => {
  const sections = [
    {
      title: 'Find Accounts',
      description: (
        <>
          Go to the{' '}
          <Link
            as={ReactRouterLink}
            color="kred.300"
            fontWeight="500"
            to={'/discover'}
          >
            Discover
          </Link>{' '}
          tab to find accounts based on your Serviceable Market filters and ICP
          score.
        </>
      ),
    },
    {
      title: 'Save & Tag',
      description:
        "When you Save & Tag accounts from your Discover tab, they'll move here to My Accounts.",
    },
    {
      title: 'Export & Sync',
      description: (
        <>
          Export your accounts to CSV or{' '}
          <Link
            color="kblue.300"
            href="https://keyplay.io/pricing/"
            target="_blank"
          >
            upgrade
          </Link>{' '}
          and continuously sync data to your CRM. Keyplay keeps your data fresh.
        </>
      ),
    },
  ];

  return (
    <Flex gap={4}>
      {sections.map((section, i) => (
        <>
          <FreemiumEmptyStateSection
            key={i}
            step={i + 1}
            title={section.title}
            description={section.description}
          />
          {/* Add a divider between sections */}
          {i < sections.length - 1 && <Divider mt="7" mx="-50px" w="150px" />}
        </>
      ))}
    </Flex>
  );
};
