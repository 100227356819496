import {useQueryClient} from '@tanstack/react-query';
import {useApiMutation} from './api';
import {PostMarket} from '../../shared/api/definitions';
import {useIsFreemiumCustomer} from './metadata';
import {ScoringModelKeys, ScoringModelTestKeys} from './scoringModel';
import {MetadataQueryKey} from '../../context/MetadataContext';
import {SamDefinitionKeys} from './samDefinition';

export const usePublishMarket = () => {
  const queryClient = useQueryClient();
  const isFreemiumCustomer = useIsFreemiumCustomer();

  return useApiMutation('/markets/publish', PostMarket, {
    onSuccess: async (_, {marketId}) => {
      const marketIdStr = marketId.toString();
      // Invalidate the scoring model and sam definition queries
      await Promise.all([
        queryClient.invalidateQueries(ScoringModelKeys.market(marketIdStr)),
        queryClient.invalidateQueries(SamDefinitionKeys.market(marketIdStr)),
        queryClient.invalidateQueries(ScoringModelTestKeys.market(marketIdStr)),
      ]);

      if (isFreemiumCustomer) {
        await queryClient.invalidateQueries([MetadataQueryKey]);
      }
    },
  });
};
