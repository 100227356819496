import React from 'react';
import {useDefaultMarketId} from '../../../hooks/api/metadata';
import {useMarketFromContext} from '../MarketIdProvider';
import {Box, Flex, HStack, Tag, Text} from '@chakra-ui/react';
import {MarketBuilderTabs} from './MarketBuilderTabs';
import {PublishButton} from './PublishButton';
import {SaveButton} from './SaveButton';
import {RevertButton} from './RevertButton';
import {AccountScorePreviewButton} from './AccountScorePreviewButton';

export const MarketBuilderHeader = () => {
  const market = useMarketFromContext();
  const primaryMarketId = useDefaultMarketId();

  return (
    <Flex
      alignItems="center"
      borderBottom="1px solid"
      borderColor="kgray.200"
      justifyContent="space-between"
      py="6"
    >
      <Flex alignItems="center" gap="4" maxW="25%" px={6}>
        <Text fontSize="2xl" isTruncated>
          {market.label}
        </Text>
        {primaryMarketId.equals(market.id) && (
          <Tag
            key="default"
            py="1"
            flexShrink={0}
            size={'sm'}
            textTransform="uppercase"
          >
            Default
          </Tag>
        )}
      </Flex>
      <Box flex={1}>
        <MarketBuilderTabs />
      </Box>
      <HStack pr={6} spacing={2}>
        <RevertButton />
        <SaveButton />
        <PublishButton />
        <AccountScorePreviewButton />
      </HStack>
    </Flex>
  );
};
