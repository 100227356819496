import _ from 'lodash';
import {
  AllKeysOf,
  AllValuesOf,
  constructZodEnumType,
  keys,
  StrictExclude,
} from './util';
import {
  BusinessAudienceLabels,
  BusinessAudiences,
  PrimaryBusinessCategories,
  PrimaryBusinessCategoryLabels,
  SecondaryBusinessCategories,
  SecondaryBusinessCategoryLabels,
} from './account';
import {typeguardFromArray} from './util';
import {z} from 'zod';
import {SignalDefinition} from './enrichment';

// Note: ordering of these signals affects what order client displays
// signals in. Intentionally not ordered alphabetically!
export const ScoringSignalsDefinition = {
  profile: {
    b2bSoftware: 'B2B Software',
    b2bAndB2c: 'B2B and B2C',

    foundedBefore1950: 'Founded before 1950',
    founded1950to1979: 'Founded 1950 to 1979',
    founded1980to1995: 'Founded 1980 to 1995',
    founded1996to2006: 'Founded 1996 to 2006',
    founded2007to2017: 'Founded 2007 to 2017',
    founded2018orLater: 'Founded 2018 or later',

    // custom
    founded2022orLater: 'Founded 2022 or later',

    // standard employee ranges
    employees1to50: 'Employees 1-50',
    employees51to100: 'Employees 51-100',
    employees101to200: 'Employees 101-200',
    employees201to500: 'Employees 201-500',
    employees501to1000: 'Employees 501-1000',
    employees1001to2500: 'Employees 1001-2500',
    employees2501to5000: 'Employees 2501-5000',
    employeesOver5000: 'Employees 5001+',

    // custom employee ranges
    employees1to300: 'Employees 1-300',
    employees101to1000: 'Employees 101-1000',
    employees301to750: 'Employees 301-750',
    employees751to3000: 'Employees 751-3000',
    employeesOver3001: 'Employees 3001+',

    hasFunding: 'Has Funding',
    fundingOver1M: 'Funding: $1M+',
    fundingAmountNotFound: 'Funding: None Found',
    fundingAmount100Kto1M: 'Funding: $100K to $1M',
    fundingAmount1Mto10M: 'Funding: $1M to $10M',
    fundingAmount10Mto50M: 'Funding: $10M to $50M',
    fundingAmount50Mto100M: 'Funding: $50M to $100M',
    fundingAmountOver100M: 'Funding: $100M+',

    lastFundingWithin3Months: 'Last Funding: 0-3 Mos',
    lastFunding4to12Months: 'Last Funding: 4 to 12 Mos',
    lastFunding13to24Months: 'Last Funding: 13 to 24 Mos',
    lastFunding25MonthsPlus: 'Last Funding: 25+ Mos',

    internationalPresence: 'International Presence',
    multipleLocations: 'Multiple Locations',
    publicCompany: 'Public Company',

    ...BusinessAudienceLabels,
    ...PrimaryBusinessCategoryLabels,
    ...SecondaryBusinessCategoryLabels,
  },
  org: {
    hiring: 'Hiring',
    hiringRemote: 'Hiring Remote Roles',
    hiringHybrid: 'Hiring Hybrid Roles',

    hasAbm: 'Has ABM',
    hasAeOrAm: 'Has AE/AM',
    hasCommunity: 'Has Community',
    hasCopywriter: 'Has Copywriter',
    hasCustomerAnalytics: 'Has Customer Analytics',
    hasCustomerExperience: 'Has Customer Experience',
    hasCustomerOperations: 'Has Customer Operations',
    hasCustomerSuccess: 'Has Customer Success',
    hasDataLeader: 'Has Data Leader',
    hasDataLeaderCustom: 'Has Data Leader (short lookback)',
    hasDataGovernance: 'Has Data Governance',
    hasDemandGen: 'Has Demand Gen',
    hasEngineeringLeader: 'Has Engineering Leader',
    hasFinance: 'Has Finance',
    hasHr: 'Has HR',
    hasIt: 'Has IT',
    hasLegal: 'Has Legal',
    hasMarketingLeader: 'Has Marketing Leader',
    hasQa: 'Has QA',
    hasRecruitingOps: 'Has Recruiting Operations',
    hasRevSalesOps: 'Has Rev/Sales Ops',
    hasSalesLeader: 'Has Sales Leader',
    hasSecurity: 'Has Security',
    hasSdrOrBdr: 'Has SDR/BDR',

    hiringAbm: 'Hiring ABM',
    hiringAeAm: 'Hiring AE/AM',
    hiringAffiliateMarketing: 'Hiring Affiliate Marketing',
    hiringBrandComms: 'Hiring Brand/Comms',
    hiringBusinessOperations: 'Hiring Business Operations',
    hiringCfdOrCae: 'Hiring CFD or CAE',
    hiringChiefOfStaff: 'Hiring Chief of Staff',
    hiringCommunity: 'Hiring Community',
    hiringCompAndBenefits: 'Hiring Comp & Benefits',
    hiringComplianceOrGrc: 'Hiring Compliance or GRC',
    hiringConsumerInsights: 'Hiring Consumer Insights',
    hiringContentMarketing: 'Hiring Content Marketing',
    hiringCopywriter: 'Hiring Copywriter',
    hiringCustomerAnalytics: 'Hiring Customer Analytics',
    hiringCustomerExperience: 'Hiring Customer Experience',
    hiringCustomerOperations: 'Hiring Customer Operations',
    hiringCustomerSuccess: 'Hiring Customer Success',
    hiringDataGovernance: 'Hiring Data Governance',
    hiringDei: 'Hiring DEI',
    hiringDemandGen: 'Hiring Demand Gen',
    hiringDigitalMarketing: 'Hiring Digital Marketing',
    hiringECommerce: 'Hiring E-Commerce',
    hiringEnterprise: 'Hiring Enterprise',
    hiringEventMarketing: 'Hiring Event Marketing',
    hiringExecutiveOrAdminAssistant: 'Hiring Exec/Admin Assistant',
    hiringFieldMarketing: 'Hiring Field Marketing',
    hiringFieldSales: 'Hiring Field Sales',
    hiringFieldSupport: 'Hiring Field Support',
    hiringFinance: 'Hiring Finance',
    hiringGrowth: 'Hiring Growth',
    hiringHr: 'Hiring HR',
    hiringIt: 'Hiring IT',
    hiringLeadershipAndDevelopment: 'Hiring L&D',
    hiringLegal: 'Hiring Legal',
    hiringMarketing: 'Hiring Marketing',
    hiringMarketingOps: 'Hiring Marketing Ops',
    hiringOmnichannelMarketing: 'Hiring Omnichannel Marketing',
    hiringOtherOps: 'Hiring Other Ops',
    hiringPartnership: 'Hiring Partnership',
    hiringPrivacy: 'Hiring Privacy',
    hiringProductLed: 'Hiring Product Led',
    hiringProductMarketing: 'Hiring Product Marketing',
    hiringProductOps: 'Hiring Product Ops',
    hiringRecruiters: 'Hiring Recruiters',
    hiringRecruitingOps: 'Hiring Recruiting Operations',
    hiringResearchOps: 'Hiring Research Ops',
    hiringRevSalesOps: 'Hiring Rev/Sales Ops',
    hiringSales: 'Hiring Sales',
    hiringSalesEnablement: 'Hiring Sales Enablement',
    hiringSdrBdr: 'Hiring SDR/BDR',
    hiringSecurity: 'Hiring Security',
    hiringSpecialists: 'Hiring Specialists',
    hiringSupport: 'Hiring Support',
    hiringSupportOperations: 'Hiring Support Operations',
    hiringSustainabilityRoles: 'Hiring Sustainability Roles',
    hiringTechnicalRecruiters: 'Hiring Technical Recruiters',
    hiringWorkforceManagement: 'Hiring Workforce Management',

    // eng roles
    hiringAi: 'Hiring Artificial Intelligence',
    hiringAiOver5: 'Hiring 6+ Artificial Intelligence Roles',
    hiringComputerVision: 'Hiring Computer Vision',
    hiringDataScience: 'Hiring Data Science',
    hiringDataTeam: 'Hiring Data Team',
    hiringDataTeamOver5: 'Hiring 6+ Data Team Roles',
    hiringDesign: 'Hiring Design',
    hiringDevExperience: 'Hiring Dev Experience',
    hiringDevOps: 'Hiring Dev Ops',
    hiringDevOpsOver5: 'Hiring 6+ Dev Ops Roles',
    hiringEng: 'Hiring Engineering',
    hiringEngOver10: 'Hiring 11+ Engineering Roles',
    hiringEngineeringManager: 'Hiring Engineering Management',
    hiringIntegrationsEng: 'Hiring Integrations Engineer',
    hiringMachineLearning: 'Hiring Machine Learning',
    hiringMobileEng: 'Hiring Mobile Engineering',
    hiringMobileEngOver5: 'Hiring 6+ Mobile Engineering Roles',
    hiringProduct: 'Hiring Product',
    hiringProductDesign: 'Hiring Product Design',
    hiringProductDesignOver5: 'Hiring 6+ Product Design Roles',
    hiringProductManagement: 'Hiring Product Management',
    hiringProgramManagement: 'Hiring Program Management',
    hiringQualityAssurance: 'Hiring QA',
    hiringSalesSolutionsEng: 'Hiring Sales/Solutions Engineering',
    hiringSecurityEng: 'Hiring Security Engineering',
    hiringSoftwareEng: 'Hiring Software Engineering',
    hiringSoftwareEng2to5: 'Hiring 2-5 Software Engineering Roles',
    hiringSoftwareEngOver5: 'Hiring 6+ Software Engineering Roles',
    hiringSoftwareEngIntern: 'Hiring Software Engineering Intern',
    hiringSolutionsEng: 'Hiring Solutions Engineering',
    hiringTechnicalPm: 'Hiring Technical PM',

    // leadership roles
    hiringCiso: 'Hiring CISO',
    hiringCustomerSuccessLeader: 'Hiring CS Leader',
    hiringDataLeader: 'Hiring Data Leader',
    hiringDesignLeader: 'Hiring Design Leader',
    hiringEngLeader: 'Hiring Engineering Leader',
    hiringFinanceLeader: 'Hiring Finance Leader',
    hiringHrLeader: 'Hiring HR Leader',
    hiringLeaders: 'Hiring Leaders (Dir+)',
    hiringMarketingLeader: 'Hiring Marketing Leader',
    hiringProductLeader: 'Hiring Product Leader',
    hiringSalesLeader: 'Hiring Sales Leader',

    oneMonthGrowthNegative: '1-mo Headcount Growth: Negative',
    oneMonthGrowthNeutral: '1-mo Headcount Growth: Neutral',
    oneMonthGrowthPositive: '1-mo Headcount Growth: Positive',

    sixMonthGrowthNegative: '6-mo Headcount Growth: Negative',
    sixMonthGrowthNeutral: '6-mo Headcount Growth: Neutral',
    sixMonthGrowthPositive: '6-mo Headcount Growth: Positive',
    sixMonthGrowthModerate: '6-mo Headcount Growth: 1% to 10%',
    sixMonthGrowthHigh: '6-mo Headcount Growth: 10% to 20%',
    sixMonthGrowthVeryHigh: '6-mo Headcount Growth: 20%+',

    // custom
    openRolesOver50: 'Open Roles 51+',
    openRoles31To50: 'Open Roles 31-50',
    hiringHackerRankSkills: 'Hiring HackerRank Skills',
    hiringHackerRankSkillsEmea: 'Hiring HackerRank Skills - EMEA',
    hiringHackerRankSkillsApac: 'Hiring HackerRank Skills - APAC',
    hiringHackerRankSkillsAmer: 'Hiring HackerRank Skills - AMER',
    seekingMarketingDecisionMakers: 'Seeking Marketing Decision Makers',
  },
  relevance: {
    aiMessaging: 'AI Messaging',
    genAiMessaging: 'Generative AI Messaging',

    remoteFirstWorkplace: 'Remote First Workplace',
    remoteWorkplace: 'Remote Workplace',
    hybridWorkplace: 'Hybrid Workplace',

    accessibilityPage: 'Accessibility Page',

    liFollowers1to50: 'LI Followers 1-50',
    liFollowers51to500: 'LI Followers 51-500',
    liFollowers501to1000: 'LI Followers 501-1000',
    liFollowers1001to5000: 'LI Followers 1001-5000',
    liFollowersOver5001: 'LI Followers 5001+',
    trafficRankHigh: 'High Website Traffic (Top 1M)',
    trafficRankTop100K: 'Traffic Rank Top 100K',
    trafficRank100Kto200K: 'Traffic Rank 100K-200K',
    trafficRank200Kto300K: 'Traffic Rank 200K-300K',
    trafficRank300Kto500K: 'Traffic Rank 300K-500K',
    trafficRank500Kto1M: 'Traffic Rank 500K-1M',
    recruitingVelocityVeryHigh: 'Recruiting Velocity: Very High',
    recruitingVelocityHigh: 'Recruiting Velocity: High',
    recruitingVelocityModerate: 'Recruiting Velocity: Moderate',
    recruitingVelocityLow: 'Recruiting Velocity: Low',
    recruitingVelocityNone: 'Recruiting Velocity: None',

    community: 'Community',
    discordCommunity: 'Discord Community',
    discourseCommunity: 'Discourse Community',
    otherCommunity: 'Other Community',
    slackCommunity: 'Slack Community',

    linkedInAds1to10: 'LinkedIn Ads: 1-10',
    linkedInAds11to100: 'LinkedIn Ads: 11-100',
    linkedInAds101Plus: 'LinkedIn Ads: 101+',

    // compliance
    jobsMentionCcpa: 'Jobs mention CCPA',
    jobsMentionGdpr: 'Jobs mention GDPR',
    jobsMentionHipaa: 'Jobs mention HIPAA',
    jobsMentionIso27001: 'Jobs mention ISO 27001',
    jobsMentionNist: 'Jobs mention NIST',
    jobsMentionPci: 'Jobs mention PCI',
    jobsMentionSoc2: 'Jobs mention SOC2',

    jobsMentionAuth: 'Jobs mention Auth',
    jobsMentionAccessControl: 'Jobs mention RBAC/ABAC',
    jobsMentionHitrust: 'Jobs mention HITRUST',

    jobsMentionCloudMigration: 'Jobs mention Cloud Migration',
    jobsMentionDigitalTransformation: 'Jobs mention Digital Transformation',

    jobsMentionAbm: 'Jobs mention ABM',
    jobsMentionAbTesting: 'Jobs mention AB Testing',
    jobsMentionCfdOrCae: 'Jobs mention CFD or CAE',
    jobsMentionColdCalling: 'Jobs mention Cold Calling',
    jobsMentionContractor: 'Jobs mention Contractor',
    jobsMentionContractorMoreThan5Times: 'Hiring >5 Contractors',
    jobsMentionDei: 'Jobs mention DEI',
    jobsMentionEhr: 'Jobs mention EHR',
    jobsMentionEmployeeTravel: 'Jobs mention Employee Travel',
    jobsMentionEquityComp: 'Jobs mention Equity Comp',
    jobsMentionHealthStipend: 'Jobs mention Health Stipend',
    jobsMentionIcp: 'Jobs mention ICP',
    jobsMentionIntegrations: 'Jobs mention Integrations',
    jobsMentionInternationalAccounting: 'Jobs mention International Accounting',
    jobsMentionLeadershipAndDevelopment: 'Jobs mention L&D',
    jobsMentionMobile: 'Jobs mention Mobile',
    usingPayEquity: 'Jobs mention Pay Equity',
    jobsMentionProductLed: 'Jobs mention Product Led',
    jobsMentionsQualitativeResearch: 'Jobs mention Qualitative Research',
    retailChannelSales: 'Jobs mention Retail Channel Sales',
    jobsMentionUserResearch: 'Jobs mention User Research',
    jobsMentionVulnerabilityManagement: 'Jobs mention Vulnerability Management',
    jobsMentionWorkforceManagement: 'Jobs mention Workforce Management',

    accessControlPricing: 'RBAC/ABAC Pricing',
    affiliateProgram: 'Affiliate Program',
    agencyProgram: 'Agency Program',
    apiDocs: 'API Docs',
    blogs: 'Blogs',
    buy: 'Buy',
    certification: 'Certification',
    cultureContent: 'Culture Content',
    customerReferralProgram: 'Customer Referral Program',
    deiContent: 'DEI Content',
    economicImpactReport: 'Total Economic Impact Report',
    demo: 'Demo',
    developerPortal: 'Developer Portal',
    downloadOrInstall: 'Download/Install',
    eCommerce: 'E-Commerce',
    emailSignUp: 'Email Sign Up',
    enterprise: 'Enterprise Offer',
    events: 'Events',
    fedramp: 'FedRAMP',
    freePlan: 'Free Plan',
    freeTrial: 'Free Trial',
    g2Badge: 'G2 Badge',
    githubPage: 'GitHub Page',
    hasLogin: 'Has Login',
    hasMobileApp: 'Has Mobile App',
    integrations: 'Integrations',
    multipleIntegrations: 'Multiple Integrations',
    multipleMarkets: 'Multiple Markets',
    multipleProducts: 'Multiple Products',
    newsletter: 'Newsletter',
    onlineReviews: 'Online Reviews',
    openSource: 'Open Source',
    partners: 'Partners',
    platform: 'Platform',
    podcast: 'Podcast',
    productLed: 'Product-Led',
    productTour: 'Product Tour',
    referralProgram: 'Referral Program',
    resellerProgram: 'Reseller Program',
    resources: 'Resources',
    retailChannel: 'Retail Channel',
    salesLed: 'Sales-Led',
    securityPage: 'Security Page',
    servicesCTA: 'Services CTA',
    smsSignUp: 'SMS Sign Up',
    supportChat: 'Support Chat',
    softwareCTA: 'Software CTA',
    solution: 'Solution',
    storeLocator: 'Store Locator',
    templates: 'Templates',
    transparentPricing: 'Transparent Pricing',
    trustCenter: 'Trust Center',
    videoContent: 'Video Content',
    visualProduct: 'Visual Product',
    webinars: 'Webinars',
  },
  stackFit: {
    silodTech: "Silo'd Tech",

    usingB2bMarketingAutomation: 'Using B2B Marketing Automation',
    usingCustomerSupport: 'Using Customer Support',
    usingECommerce: 'Using E-Commerce',
    usingModernDataStack: 'Using Modern Data Stack',
    usingMultipleWebAnalytics: 'Using Multiple Web Analytics',

    usingDigitalAds: 'Using Digital Ads',
    usingMultipleDigitalAds: 'Using Multiple Digital Ads',

    usingAi: 'Using AI',
    usingComputerVision: 'Using Computer Vision',
    computerVisionBriaCustom: 'Jobs mention Computer Vision (Bria)',
    usingGenerativeAi: 'Using Gen AI',
    usingMachineLearning: 'Using Machine Learning',

    usingFieldServiceManagement: 'Using Field Service Management',

    usingPublicCloud: 'Using Public Cloud',
    usingAws: 'AWS',
    usingAzure: 'Microsoft Azure',
    usingGcp: 'GCP',

    usingCiCd: 'Using CI/CD',
    circleCi: 'CircleCI',
    jenkins: 'Jenkins',
    teamCity: 'TeamCity',
    travisCi: 'Travis CI',

    ansible: 'Ansible',
    bitbucket: 'Atlassian Bitbucket',
    buildkite: 'Buildkite',
    github: 'GitHub',
    gitlab: 'GitLab',
    jfrog: 'jFrog',
    puppet: 'Puppet',
    usingPulumi: 'Pulumi',
    usingTerraform: 'Terraform',

    apiiro: 'Apiiro',
    blackDuck: 'Black Duck',
    checkmarx: 'Checkmarx',
    codeQL: 'CodeQL',
    coverity: 'Coverity',
    grype: 'Grype',
    snyk: 'Snyk',
    trivy: 'Trivy',
    veracode: 'Veracode',
    wiz: 'Wiz',

    usingAbTasty: 'AB Tasty',
    usingLaunchDarkly: 'LaunchDarkly',
    usingOptimizely: 'Optimizely',
    usingSplitIo: 'Split.io',
    usingStatsig: 'Statsig',

    usingSfdcServiceCloud: 'SFDC Service Cloud',
    usingZendesk: 'Zendesk',
    newlyUsingZendesk: 'Newly Using Zendesk',

    usingAts: 'Using ATS',
    noAtsFound: 'No ATS Found',
    unknownAts: 'Has Unknown ATS',
    adpAts: 'ADP ATS',
    angellistAts: 'AngelList ATS',
    usingAshby: 'Ashby',
    bambooHr: 'Bamboo HR',
    breezyHr: 'Breezy HR',
    comparably: 'Comparably',
    eightfold: 'Eightfold',
    usingGreenhouse: 'Greenhouse',
    usingIcims: 'iCIMS',
    jazzHr: 'JazzHR',
    usingJobvite: 'Jobvite',
    usingLever: 'Lever',
    linkedInAts: 'LinkedIn ATS',
    paycom: 'Paycom',
    paylocity: 'Paylocity',
    personio: 'Personio',
    usingRecruitee: 'Recruitee',
    recruiterflow: 'Recruiterflow ATS',
    ripplingAts: 'Rippling ATS',
    usingSmartRecruiters: 'SmartRecruiters',
    talentEd: 'TalentEd',
    teamtailor: 'Teamtailor',
    ultipro: 'UltiPro ATS',
    usingWorkable: 'Workable',
    usingWorkdayAts: 'Workday ATS',
    yCombinatorAts: 'Y Combinator ATS',

    usingChatGpt: 'ChatGPT',
    usingOpenAi: 'Open AI',
    usingPromptEngineering: 'Prompt Engineering',

    dialogflow: 'Dialogflow',
    kubeFlow: 'KubeFlow',
    usingPytorch: 'PyTorch',
    tensorFlow: 'TensorFlow',

    powerBi: 'Microsoft Power BI',
    qlik: 'Qlik',
    starburst: 'Starburst',

    alation: 'Alation',
    atlan: 'Atlan',
    collibra: 'Collibra',

    usingDataWarehouse: 'Using Data Warehouse',
    usingAzureSynapse: 'Azure Synapse',
    usingBigQuery: 'Big Query',
    usingDatabricks: 'Databricks',
    usingOracleAutonomousDataWarehouse: 'Oracle Autonomous Data Warehouse',
    usingPanoply: 'Panoply',
    usingRedshift: 'Redshift',
    usingSapDataWarehouseCloud: 'SAP Data Warehouse Cloud',
    usingSnowflake: 'Snowflake',
    usingTeradataVantage: 'Teradata Vantage',
    usingVertica: 'Vertica',
    usingYellowbrick: 'Yellowbrick',

    alteryx: 'Alteryx',
    apacheAirflow: 'Apache Airflow',
    dbt: 'dbt',
    informatica: 'Informatica',
    matillion: 'Matillion',
    rivery: 'Rivery',
    talend: 'Talend',

    concur: 'Concur',
    expensify: 'Expensify',

    fleetio: 'Fleetio',
    geotab: 'Geotab',
    samsara: 'Samsara',

    usingGeneralLedger: 'Using General Ledger',
    acumatica: 'Acumatica',
    epicor: 'Epicor',
    usingFinancialForce: 'FinancialForce',
    usingIntacct: 'Intacct',
    microsoftDynamicsGp: 'Microsoft Dynamics GP',
    usingNetsuite: 'Netsuite',
    oracleFusion: 'Oracle Fusion',
    usingQuickbooks: 'QuickBooks',
    usingQuickbooksOnline: 'QuickBooks Online',
    sapCloudErp: 'SAP Cloud ERP',
    usingXero: 'Xero',

    usingContainerization: 'Using Containerization',
    docker: 'Docker',
    usingKubernetes: 'Kubernetes',

    golang: 'Golang',
    usingJava: 'Java',
    javascript: 'JavaScript',
    usingPython: 'Python',
    typescript: 'TypeScript',

    usingCloudflare: 'Cloudflare',
    freshworks: 'Freshworks',
    usingGong: 'Gong',
    usingHubSpot: 'HubSpot CRM',
    usingInfor: 'Infor',
    usingJde: 'JDE',
    microservices: 'Microservices',
    usingMongoDb: 'MongoDB',
    outreach: 'Outreach',
    usingWorkday: 'Workday',

    usingDatadog: 'Datadog',
    usingNewRelic: 'New Relic',
    prometheus: 'Prometheus',
    selenium: 'Selenium',
    usingSplunk: 'Splunk',

    clearbit: 'Clearbit',
    experian: 'Experian',

    qualtrics: 'Qualtrics',
    userTesting: 'UserTesting',
    userZoom: 'UserZoom',

    usingLegacyTech: 'Using Legacy Tech',
    cobol: 'COBOL',
    coldFusion: 'ColdFusion',
    documentum: 'Documentum',
    ektron: 'Ektron',
    ibm: 'IBM',
    lotusNotes: 'Lotus Notes',
    mainframe: 'Mainframe',
    oracle: 'Oracle',
    sap: 'SAP',
    sharepoint: 'SharePoint',
    siebel: 'Siebel',
    sqlServer: 'SQL Server',

    usingLms: 'Using LMS',
    absorbLms: 'Absorb LMS',
    docebo: 'Docebo',
    intellum: 'Intellum',
    learnUpon: 'LearnUpon',
    litmos: 'Litmos',
    skilljar: 'Skilljar',
    talentLms: 'TalentLMS',

    cordial: 'Cordial',
    selligent: 'Selligent',
    zaius: 'Zaius',

    cheetahMail: 'CheetahMail',
    dotmailer: 'Dotmailer',
    silverpop: 'Silverpop',

    bill: 'Bill.com',
    tipalti: 'Tipalti',

    tray: 'Tray.io',
    workato: 'Workato',
    zapier: 'Zapier',

    asana: 'Asana',
    confluence: 'Atlassian Confluence',
    jira: 'Atlassian Jira',
    slackApp: 'Slack',

    usingCustomerSuccessPlatform: 'Using Customer Success Platform',
    catalyst: 'Catalyst (CSP)',
    churnzero: 'ChurnZero',
    hivebrite: 'Hivebrite',
    planhat: 'Planhat',
    strikedeck: 'Strikedeck',
    totango: 'Totango',
    vitally: 'Vitally',

    fivetran: 'Fivetran',

    // page signals
    stripe: 'Stripe',

    // element signals
    housecallPro: 'Housecall Pro',
    jobber: 'Jobber',
    serviceTitan: 'Service Titan',

    usingMultipleSocialMedia: 'Using Multiple Social Media',
    facebook: 'Facebook',
    twitter: 'X/Twitter',
    instagram: 'Instagram',
    youtubeChannel: 'Youtube Channel',
    tiktok: 'TikTok',
    pinterest: 'Pinterest',

    // source signals
    allbound: 'Allbound',
    channeltivity: 'Channeltivity',
    kiflo: 'Kiflo',
    linkMink: 'LinkMink',
    partnerStack: 'PartnerStack',
    zift: 'Zift',

    drata: 'Drata',
    vanta: 'Vanta',

    safeBase: 'SafeBase',

    fleetPictured: 'Fleet Pictured',

    blotout: 'Blotout',
    blueconic: 'BlueConic',
    kustomer: 'Kustomer',
    lytics: 'Lytics',
    msftDynamics365: 'Microsoft Dynamics 365',
    rudderStack: 'RudderStack',

    // external signals
    pagerduty: 'PagerDuty',
    cornerstoneOnDemand: 'Cornerstone OnDemand',

    // dns signals
    auth0: 'Auth0',

    usingCommunitySoftware: 'Using Community Software',
    discourse: 'Discourse',
    vanillaForums: 'Higher Logic Vanilla',
    inSided: 'inSided',
    mightyNetworks: 'Mighty Networks',

    usingProductExperienceSoftware: 'Using Product Experience Software',
    amplitude: 'Amplitude',
    appcues: 'Appcues',
    contentsquare: 'Contentsquare',
    fullStory: 'FullStory',
    heap: 'Heap',
    hotjar: 'Hotjar',
    intercom: 'Intercom',
    pendo: 'Pendo',
    walkMe: 'WalkMe',

    // wapp tech
    activeCampaign: 'Active Campaign',
    adRoll: 'AdRoll',
    adaSupport: 'Ada Support',
    addSearch: 'AddSearch',
    adobeAnalytics: 'Adobe Analytics',
    adobeAudienceManager: 'Adobe Audience Manager',
    adobeExperienceManager: 'Adobe Experience Manager',
    adobeTarget: 'Adobe Target Standard',
    ahrefs: 'Ahrefs',
    akamai: 'Akamai',
    algolia: 'Algolia',
    amazonAdvertising: 'Amazon Advertising',
    amazonPay: 'Amazon Pay',
    amazonS3: 'Amazon S3',
    amazonSes: 'Amazon Simple Email Service',
    ambassador: 'Ambassador',
    attentive: 'Attentive',
    autopilot: 'Autopilot',
    awin: 'AWIN',
    bazaarVoice: 'Bazaarvoice Reviews',
    bigCommerce: 'BigCommerce',
    bloomReach: 'Bloomreach',
    bluecore: 'Bluecore',
    blueshift: 'Blueshift',
    braze: 'Braze',
    brightcove: 'Brightcove',
    bronto: 'Bronto',
    callRail: 'CallRail',
    campaignMonitor: 'Campaign Monitor',
    capterra: 'Capterra',
    cartFunctionaliyt: 'Cart Functionality',
    chiliPiper: 'Chili Piper',
    chorus: 'Chorus',
    clearbitReveal: 'Clearbit Reveal',
    cleverTap: 'CleverTap',
    clicky: 'Clicky',
    cloudFront: 'Amazon CloudFront',
    cloudinary: 'Cloudinary',
    comScore: 'comScore',
    comeet: 'Comeet',
    constantContact: 'Constant Contact',
    contentful: 'Contentful',
    contently: 'Contently',
    coveo: 'Coveo',
    crazyEgg: 'CrazyEgg',
    crisp: 'Crisp',
    criteo: 'Criteo',
    customerIo: 'Customer.io',
    dataDome: 'DataDome',
    demandbase: 'Demandbase',
    docusign: 'DocuSign',
    dreamHost: 'DreamHost',
    drift: 'Drift',
    drip: 'Drip',
    drupal: 'Drupal',
    dynamicYield: 'Dynamic Yield',
    elementor: 'Elementor',
    eloqua: 'Eloqua',
    emarsys: 'Emarsys',
    engagio: 'Engagio',
    envoy: 'Envoy',
    exponea: 'Exponea',
    facebookConversionTracking: 'Facebook Conversion Tracking',
    fastly: 'Fastly',
    firebase: 'Firebase',
    freshteam: 'Freshteam',
    gitHubHoster: 'GitHub Pages',
    googleAds: 'Google Ads',
    googleAnalytics: 'Google Analytics',
    googleAppsForBusiness: 'Google Workspace',
    googleCloudCdn: 'Google Cloud CDN',
    googleOptimize360: 'Google Optimize 360',
    googlePay: 'Google Pay',
    googleTagManager: 'Google Tag Manager',
    gusto: 'Gusto',
    heroku: 'Heroku',
    hibob: 'Hibob',
    hostgator: 'Hostgator',
    hubspot: 'Hubspot',
    hubspotCmsHub: 'HubSpot CMS Hub',
    impact: 'Impact',
    imperva: 'Imperva',
    infusionsoft: 'Keap (Infusionsoft)',
    insider: 'Insider',
    instapage: 'Instapage',
    iterable: 'Iterable',
    joomla: 'Joomla',
    kissMetrics: 'KISSmetrics',
    klaviyo: 'Klaviyo',
    leadfeeder: 'Leadfeeder',
    leanplum: 'Leanplum',
    linkedInAds: 'LinkedIn Ads',
    listrak: 'Listrak',
    livestorm: 'Livestorm',
    mParticle: 'mParticle',
    magento: 'Magento',
    mailChimp: 'MailChimp',
    mailgun: 'Mailgun',
    mapp: 'Mapp (BlueHornet)',
    marketo: 'Adobe Marketo',
    microsoftAdvertising: 'Microsoft Advertising',
    mixpanel: 'Mixpanel',
    moEngage: 'MoEngage',
    movableInk: 'Movable Ink',
    mySql: 'MySQL',
    netlify: 'Netlify',
    nginx: 'NGINX',
    office365Mail: 'Microsoft Office 365',
    okta: 'Okta',
    ometria: 'Ometria',
    on24: 'ON24',
    oneSignal: 'OneSignal',
    oneTrust: 'OneTrust',
    outbrain: 'Outbrain',
    pantheon: 'Pantheon',
    pardot: 'Salesforce Marketing Cloud (Pardot)',
    perimeterX: 'PerimeterX',
    pinterestAds: 'Pinterest Ads',
    podium: 'Podium',
    posthog: 'Posthog',
    powerReviews: 'PowerReviews',
    qualified: 'Qualified',
    quantcastMeasure: 'Quantcast Measure',
    queueIt: 'Queue-it',
    quora: 'Quora Pixel',
    reCAPTCHA: 'reCAPTCHA',
    react: 'React',
    recharge: 'Recharge Payments',
    reddit: 'Reddit',
    redditAds: 'Reddit Ads',
    rewardful: 'Rewardful',
    sailthru: 'Sailthru',
    salesLoft: 'Salesloft',
    salesforce: 'Salesforce',
    segment: 'Segment',
    semrush: 'Semrush',
    sendgrid: 'Sendgrid',
    sentry: 'Sentry',
    serviceNow: 'ServiceNow',
    sharpSpring: 'SharpSpring',
    shopify: 'Shopify',
    sitecore: 'Sitecore',
    siteimprove: 'Siteimprove',
    sixSense: '6sense',
    snowplowAnalytics: 'Snowplow Analytics',
    sparkpost: 'SparkPost',
    squarespace: 'Squarespace',
    squarespaceCommerce: 'Squarespace Commerce',
    tapfiliate: 'Tapfiliate',
    tealium: 'Tealium',
    tiktokPixel: 'TikTok Pixel',
    transcend: 'Transcend',
    trustpilot: 'Trustpilot',
    twilio: 'Twilio Authy',
    twitterAds: 'Twitter Ads',
    typeform: 'Typeform',
    unbounce: 'Unbounce',
    unpkg: 'UNPKG',
    vercel: 'Vercel',
    vidyard: 'Vidyard',
    vimeo: 'Vimeo',
    vwo: 'VWO Engage',
    webEngage: 'WebEngage',
    webflow: 'Webflow',
    whatfix: 'Whatfix',
    wistia: 'Wistia',
    wix: 'Wix',
    wordpress: 'Wordpress',
    wpEngine: 'WP Engine',
    yotpo: 'Yotpo Reviews',
    youTube: 'YouTube',
    zohoMail: 'Zoho Mail',
    zoom: 'Zoom',
    zoomInfo: 'ZoomInfo',
  },
} as const;

export type KeyplayScoringSignal = AllKeysOf<
  (typeof ScoringSignalsDefinition)[keyof typeof ScoringSignalsDefinition]
>;

export type StackFitSignal = AllKeysOf<
  (typeof ScoringSignalsDefinition)['stackFit']
>;

export type ScoringSignalLabel = AllValuesOf<
  (typeof ScoringSignalsDefinition)[keyof typeof ScoringSignalsDefinition]
>;

export type ScoringCategory = keyof typeof ScoringSignalsDefinition;

export const ScoringCategorySignalLabels = {
  profile: 'Profile Signals',
  org: 'Org Signals',
  relevance: 'Relevance Signals',
  stackFit: 'Stack Signals',
} as const satisfies Record<ScoringCategory, string>;

export const ScoringCategoryFitLabels = {
  profile: 'Profile Fit',
  org: 'Org Fit',
  relevance: 'Relevance',
  stackFit: 'Stack Fit',
} as const satisfies Record<ScoringCategory, string>;

// signal -> display name
export const ScoringSignalToLabel = <
  Record<KeyplayScoringSignal, ScoringSignalLabel>
>Object.values(ScoringSignalsDefinition).reduce((prev, curr) => ({
  ...prev,
  ...curr,
}));

// signal -> category
export const ScoringSignalToCategory = <
  Record<KeyplayScoringSignal, ScoringCategory>
>Object.entries(ScoringSignalsDefinition).reduce(
  (prev, [currCategory, currData]) => {
    const currentSignalsToTags: {
      [signal in KeyplayScoringSignal]?: ScoringCategory;
    } = Object.fromEntries(
      Object.keys(currData).map((signal) => [signal, currCategory])
    );
    return {...prev, ...currentSignalsToTags};
  },
  {}
);

export const ScoringCategories = keys(ScoringSignalsDefinition);
export const OrgSignals = keys(ScoringSignalsDefinition['org']);
export const ProfileSignals = keys(ScoringSignalsDefinition['profile']);
export const RelevanceSignals = keys(ScoringSignalsDefinition['relevance']);
export const StackFitSignals = keys(ScoringSignalsDefinition['stackFit']);
export const KeyplayScoringSignals = [
  ...OrgSignals,
  ...ProfileSignals,
  ...RelevanceSignals,
  ...StackFitSignals,
];
export const isKeyplayScoringSignal = typeguardFromArray(KeyplayScoringSignals);

export const OptionalScoringSignals = [
  'b2bSoftware',

  'hiringOtherOps',
  'hiringSpecialists',
  'jobsMentionContractorMoreThan5Times',
  'servicesCTA',

  // Andela
  'hiringAiOver5',
  'hiringDataTeamOver5',
  'hiringDevOpsOver5',
  'hiringProductDesignOver5',
  'hiringMobileEngOver5',
  'hiringEngOver10',

  // reprise
  'employees1to300',
  'employees301to750',
  'employees751to3000',
  'employeesOver3001',

  // lang
  'employees101to1000',

  // brighthire
  'openRoles31To50',
  'openRolesOver50',

  // HackerRank
  'hiringHackerRankSkills',
  'hiringHackerRankSkillsEmea',
  'hiringHackerRankSkillsApac',
  'hiringHackerRankSkillsAmer',

  // Iterable
  'emailSignUp',
  'silodTech',
  'smsSignUp',
  'seekingMarketingDecisionMakers',

  // UserEvidence
  'economicImpactReport',

  // Chainguard
  'fedramp',

  // Prismatic
  'jobsMentionIntegrations',

  // Kubit
  'founded2022orLater',
  'hasDataLeaderCustom',

  // Bria
  'computerVisionBriaCustom',
] as const satisfies readonly KeyplayScoringSignal[];

export type OptionalScoringSignal = (typeof OptionalScoringSignals)[number];
export const isOptionalScoringSignal = typeguardFromArray(
  OptionalScoringSignals
);

export const CoreScoringSignals = _.difference(
  KeyplayScoringSignals,
  OptionalScoringSignals
);
export type CoreScoringSignal = StrictExclude<
  KeyplayScoringSignal,
  OptionalScoringSignal
>;

export const CoreProfileSignals = _.difference(
  ProfileSignals,
  OptionalScoringSignals
);
export const CoreOrgSignals = _.difference(OrgSignals, OptionalScoringSignals);
export const CoreRelevanceSignals = _.difference(
  RelevanceSignals,
  OptionalScoringSignals
);
export const CoreStackFitSignals = _.difference(
  StackFitSignals,
  OptionalScoringSignals
);
export type CoreStackFitSignal = Exclude<StackFitSignal, OptionalScoringSignal>;

// signals that aren't available for use in the SAM (usually because they're duplicative)
export const ScoringOnlySignals: KeyplayScoringSignal[] = [
  'b2bSoftware',
  'employees1to50',
  'employees51to100',
  'employees101to200',
  'employees201to500',
  'employees501to1000',
  'employees1001to2500',
  'employees2501to5000',
  'employeesOver5000',
  'employees1to300',
  'employees301to750',
  'employees751to3000',
  'employeesOver3001',
  ...BusinessAudiences,
  ...PrimaryBusinessCategories,
  ...SecondaryBusinessCategories,
];

// zod schemas that we define after everything else, because the type needs to be
// defined first so we aren't able to infer the type from the schema
export const KeyplayScoringCategorySchema =
  constructZodEnumType(ScoringCategories);

export const KeyplayScoringSignalSchema = constructZodEnumType(
  KeyplayScoringSignals
);
export const StackFitSignalSchema = constructZodEnumType(StackFitSignals);

export const CustomSignalSchema = z.string();
export type CustomSignal = z.infer<typeof CustomSignalSchema>;

// Note: keep the schema and type in sync with each other if we want both accurate
// type hinting and runtime validation. zod doesn't seem to support the workarounds
// described in https://github.com/microsoft/TypeScript/issues/29729
export const ScoringSignalSchema =
  KeyplayScoringSignalSchema.or(CustomSignalSchema);
export type ScoringSignal = KeyplayScoringSignal | (CustomSignal & {});

export type ResolvedScoringSignal = {
  id: ScoringSignal;
  label: string;
  category: ScoringCategory;
  source: 'custom' | 'keyplay';
};
export type ScoringSignalResolver = (
  scoringSignal: ScoringSignal
) => ResolvedScoringSignal | null;

export function resolveScoringSignal(
  scoringSignal: ScoringSignal,
  signalDefinitions: SignalDefinition[]
): ResolvedScoringSignal | null {
  if (isKeyplayScoringSignal(scoringSignal)) {
    return {
      id: scoringSignal,
      label: ScoringSignalToLabel[scoringSignal],
      category: ScoringSignalToCategory[scoringSignal],
      source: 'keyplay',
    };
  } else {
    const customSignal = signalDefinitions.find(
      (signal) => signal.id === scoringSignal
    );

    if (!customSignal) {
      return null;
    }

    return {
      id: customSignal.id,
      label: customSignal.label,
      category: customSignal.category,
      source: 'custom',
    };
  }
}
