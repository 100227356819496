import {useInfiniteQuery} from '@tanstack/react-query';
import {useState} from 'react';
import {useKeyplayApi} from '../../context/ApiContext';
import {ScoredAccountsResponse} from '../../shared/api/responses';
import {useAccountQueryParams} from '../AccountGrid/AccountGrid.controlstate';
import {ScoredAccount} from '../../shared/scoredAccounts';

export function useScoredAccounts() {
  const queryParams = useAccountQueryParams();

  const makeApiCall = useKeyplayApi();
  const infiniteQuery = useInfiniteQuery<ScoredAccountsResponse>(
    ['scoredAccountsTables', JSON.stringify(queryParams)],
    async ({pageParam}) => {
      if (!pageParam) {
        return makeApiCall<ScoredAccountsResponse>(
          `/scoredAccounts?query=${JSON.stringify({
            ...queryParams,
            limit: 80,
          })}`
        );
      }
      return makeApiCall<ScoredAccountsResponse>(
        `/scoredAccounts?cursor=${pageParam}`
      );
    },
    {
      onSuccess: (data) => {
        setFetchedAccounts(data?.pages?.flatMap((resp) => resp.accounts) ?? []);
      },
      getNextPageParam: (_lastGroup) => _lastGroup.cursor,
      keepPreviousData: true,
    }
  );

  // intentionally use state to keep the array reference stable for consumers
  const [fetchedAccounts, setFetchedAccounts] = useState<ScoredAccount[]>(
    infiniteQuery.data?.pages?.flatMap((resp) => resp.accounts) ?? []
  );

  const totalCount = infiniteQuery.data?.pages?.[0].totalLength;
  const scoringRun = infiniteQuery.data?.pages?.[0]?.scoringRun;

  return {
    fetchedAccounts,
    totalCount,
    scoringRun,
    ...infiniteQuery,
  };
}
