import React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {AllIntegrations} from './AllIntegrations';
import {
  useIsCrmSyncing,
  useQueueFullCrmRun,
} from '../../hooks/api/integrations';
import {CustomerIntegration} from '../../shared/customer';
import {formatRelative} from 'date-fns';
import {useIntegrationDetailsState} from './IntegrationDetails.state';
import {StatusBadge} from './IntegrationDetails';
import {DisconnectIntegrationModal} from './DisconnectIntegrationModal';
import {ChakraSettings} from '../Icons/Carbon';
import {DeleteIntegrationModal} from './DeleteIntegrationModal';

export const StatusHeader = ({
  integrationName,
}: {
  integrationName: CustomerIntegration;
}) => {
  const integrationInfo = AllIntegrations[integrationName];
  const queueFullCrmRun = useQueueFullCrmRun();
  const toast = useToast();
  const disconnectIntegrationDisclosure = useDisclosure();
  const deleteIntegrationDisclosure = useDisclosure();

  const {
    hasInitialized,
    query: integrationDetailsQuery,
    serverState,
  } = useIntegrationDetailsState(integrationName);

  const isSyncInProgress = useIsCrmSyncing({
    onSyncComplete: (taskState) => {
      if (taskState === 'done') {
        toast({
          title: 'Sync complete',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        integrationDetailsQuery.refetch();
      }
    },
  });

  const onSyncClicked = () => {
    queueFullCrmRun.mutate(integrationName);

    toast({
      title: 'Sync started',
      status: 'info',
      duration: 5000,
      isClosable: true,
    });
  };

  if (!hasInitialized) {
    return (
      <Center py={20}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex m={8} alignItems={'center'} gap={8}>
      <Flex
        width="100px"
        height="100px"
        bgColor={'white'}
        borderRadius={'lg'}
        p={4}
        flexShrink={0}
      >
        {integrationInfo.logo}
      </Flex>
      <VStack flexGrow={1} alignItems={'start'}>
        <Box fontSize={'2xl'}>{integrationInfo.label}</Box>
        <StatusBadge status={serverState?.status} />
      </VStack>
      <VStack>
        <Flex gap="2">
          {serverState?.status === 'active' ? (
            <Button
              colorScheme="kbuttonblue"
              isDisabled={isSyncInProgress}
              isLoading={queueFullCrmRun.isLoading}
              fontWeight={400}
              onClick={onSyncClicked}
              px="8"
            >
              Sync Now
            </Button>
          ) : (
            <Button
              colorScheme="kbuttonblue"
              fontWeight={400}
              onClick={() => location.assign(integrationInfo.oauthUrl)}
              px="8"
            >
              Connect
            </Button>
          )}
          <Menu>
            <MenuButton as={Button} isDisabled={isSyncInProgress}>
              <ChakraSettings />
            </MenuButton>
            <MenuList>
              <MenuItem
                isDisabled={serverState?.status === 'disconnected'}
                onClick={disconnectIntegrationDisclosure.onOpen}
              >
                Disconnect
              </MenuItem>
              <MenuItem onClick={deleteIntegrationDisclosure.onOpen}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
          <DisconnectIntegrationModal
            integrationName={integrationName}
            isOpen={disconnectIntegrationDisclosure.isOpen}
            onClose={disconnectIntegrationDisclosure.onClose}
          />
          <DeleteIntegrationModal
            integrationName={integrationName}
            isOpen={deleteIntegrationDisclosure.isOpen}
            onClose={deleteIntegrationDisclosure.onClose}
          />
        </Flex>
        <Box color={'kgray.300'} fontSize={'sm'} alignSelf="start">
          {isSyncInProgress
            ? 'Sync in progress...'
            : serverState?.lastEnrichment
            ? `Last sync: ${formatRelative(
                serverState.lastEnrichment,
                new Date()
              )}`
            : ''}
        </Box>
      </VStack>
    </Flex>
  );
};
