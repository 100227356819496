import {ObjectId} from 'bson';
import {AccountFields} from './fields';
import {ScoringSignal, KeyplayScoringSignal} from './signals';
import {SearchableValue, StrictOmit, keys, constructZodEnumType} from './util';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from './account';
import {AccountWithLogo} from './api/responses';
import {z} from 'zod';
import {CustomFields} from './enrichment';

export const AccountTierLabels = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
} as const;

export const AccountTiers = keys(AccountTierLabels);
export const AccountTierSchema = constructZodEnumType(AccountTiers);
export type AccountTier = z.infer<typeof AccountTierSchema>;

export const AccountTierOverride = z.union([
  AccountTierSchema,
  z.literal('none'),
]);
export type AccountTierOverride = z.infer<typeof AccountTierOverride>;

export type Rollup = {
  id: ObjectId;
  label: string;
  signals: KeyplayScoringSignal[];
};

export interface ScoringRun {
  timestamp: Date;
  customerId: ObjectId;
  parentTaskId: ObjectId;

  // set after the run has finished
  samSize?: number;
  lookalikesWithLogos?: AccountWithLogo[];
}

export interface ScoredAccountMutableState {
  mutated: boolean; // has the state been mutated?

  active: boolean;
  disqualified: boolean;
  excluded: boolean;
  saved: boolean;
  saveDate?: Date;

  displayTier: AccountTier;
  tierOverride: AccountTierOverride;
  tags: ObjectId[];
}

export const CustomerAccountSourceLabels = {
  crm: 'CRM',
  saved: 'Keyplay (Saved)',
  imported: 'Keyplay (Imported)',
} as const;

export type CustomerAccountSource = keyof typeof CustomerAccountSourceLabels;

export type MarketScores = {
  marketId: ObjectId;

  isInSam: boolean;
  isPrimaryMarket: boolean;
  matchedSignals: ScoringSignal[];

  overallFit: number;
  tier: AccountTier;
  signalScore: number;
  momentumScore: number;
  mostSimilarTo?: {accountId: ObjectId; score: number};
};

export interface ScoredAccount {
  runId: ObjectId;

  // these are the only fields we allow to be mutated
  searchable: SearchableValue[];
  mutatedState: ScoredAccountMutableState;

  originalState: StrictOmit<
    ScoredAccountMutableState,
    // we don't need the original state for these fields
    'displayTier' | 'mutated' | 'saveDate' | 'tags'
  >;

  // customer account fields
  imported: boolean; // needed for indexing
  imports: ObjectId[];
  inCrm: boolean;
  recommended: boolean;

  // scoring data
  fields: AccountFields;
  customFields?: CustomFields;
  matchedSignals: ScoringSignal[];
  rollups: Rollup[];
  tier: AccountTier;

  // results for each scoring model. if we wrote a scoring run for each model
  // we wouldn't need this (but it could still be useful for convenience)
  marketScores: MarketScores[];

  overallFit: number;
  momentumScore: number;
  signalScore: number;
  mostSimilarTo?: {accountId: ObjectId; score: number};

  // account data
  accountDomain: string;
  accountId: ObjectId;
  accountLinkedIn: string;
  audience?: BusinessAudience[];
  primaryCategory?: PrimaryBusinessCategory[];
  secondaryCategory?: SecondaryBusinessCategory[];
  source?: CustomerAccountSource;
}

export interface Tag {
  customerId: ObjectId;
  timestamp: Date;
  name: string;
}
