import {useImportModalStore} from './useImportModalStore';
import {FileValidationError} from '../../lib/validateFile';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import {WarningAlt} from '@carbon/icons-react';
import {FileChooser} from './FileChooser';
import * as Sentry from '@sentry/react';
import {isRecommendedAccountsImportRow} from '../../shared/import';
import {FileValidator} from './FileValidator';
import {errorMap, FileValidationErrors} from './FileValidationErrors';
import {useKeyplayApi} from '../../context/ApiContext';
import {useMutation} from '@tanstack/react-query';
import {FileCRMImportResponse} from '../../shared/api/api';
import {useSignedUrlMutation} from './useSignedUrlMutation';
import {useUploadFileMutation} from './useUploadFileMutation';
import {SubmitHandler, useForm} from 'react-hook-form';
import {ImportSourceName} from '../../shared/validation';

const FileChooserStep = () => {
  const {selectedFile, setState} = useImportModalStore();

  return (
    <>
      <FileChooser
        selectedFile={selectedFile}
        onSelectedFile={(file) => {
          setState({
            selectedFile: file,
            step: 'validateFile',
          });
        }}
      />

      {!selectedFile && <InfoBox />}
    </>
  );
};

const ValidateFileStep = () => {
  const {selectedFile, setState} = useImportModalStore();
  if (!selectedFile) {
    Sentry.captureMessage('validate file step but no file selected');
    return <></>;
  }

  return (
    <FileValidator
      maxRows={10_000}
      onFileValidate={(errors) => {
        if (errors.size > 0) {
          setState({
            fileValidationErrors: errors,
            step: 'errors',
          });
        } else {
          setState({
            fileValidationErrors: null,
            step: 'uploadFile',
          });
        }
      }}
      rowSchemaTypeguard={isRecommendedAccountsImportRow}
      selectedFile={selectedFile}
    />
  );
};

interface FormData {
  source: string;
}

const UploadFileStep = ({
  onClose,
}: {
  onClose: (taskId: string | null) => void;
}) => {
  const toast = useToast();
  const makeApiCall = useKeyplayApi();
  const {selectedFile, remoteFileLocation, setState} = useImportModalStore();
  const {
    formState: {errors},
    handleSubmit,
    getValues,
    register,
  } = useForm<FormData>();

  const queueRecommendedAccountsImport = useMutation<FileCRMImportResponse>({
    mutationFn: () => {
      return makeApiCall(
        '/recommendedAccountsImport',
        {
          method: 'POST',
          data: {
            cloudStorageBucket: remoteFileLocation?.cloudStorageBucket,
            cloudStorageFilePath: remoteFileLocation?.cloudStorageFilePath,
            source: getValues('source'),
          },
        },
        {toastOnError: true}
      );
    },
    onSuccess: (data) => {
      toast({
        status: 'success',
        title: 'Import process started!',
        duration: 2_000,
        isClosable: true,
      });
      onClose(data.taskId || null);
    },
  });

  const uploadFileMutation = useUploadFileMutation({
    selectedFile,
    onSuccess: () => {
      queueRecommendedAccountsImport.mutate();
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        status: 'error',
        title: 'Error uploading file',
        duration: 2_000,
        isClosable: true,
      });
      onClose(null);
    },
  });

  const getSignedURLMutation = useSignedUrlMutation({
    selectedFile: selectedFile,
    onSuccess: (data) => {
      setState({
        remoteFileLocation: data,
      });
      uploadFileMutation.mutate({url: data.url});
    },
    onError: (error) => {
      Sentry.captureException(error);
      toast({
        status: 'error',
        title: ' Error requesting upload location',
        isClosable: true,
      });
      onClose(null);
    },
  });

  const handleImportClick: SubmitHandler<FormData> = () => {
    if (!selectedFile) {
      return;
    }
    getSignedURLMutation.mutate();
  };

  const isLoading =
    getSignedURLMutation.isLoading ||
    uploadFileMutation.isLoading ||
    queueRecommendedAccountsImport.isLoading;
  return (
    <Flex direction="column" alignItems="center" gap={8}>
      <Flex alignItems="center">
        Your file looks amazing! Lets add a{' '}
        <Box mx={1} as="b">
          source
        </Box>{' '}
        tag so you know where your accounts came from.
      </Flex>
      <Flex direction="column" gap={8}>
        <Box as="form" onSubmit={handleSubmit(handleImportClick)} width="100%">
          <Flex direction="column" alignItems="center">
            <FormControl isRequired isInvalid={!!errors.source} width="50%">
              <FormLabel>Source name</FormLabel>
              <Input
                isDisabled={isLoading}
                isInvalid={!!errors.source}
                size="md"
                maxLength={ImportSourceName.maxLength}
                {...register('source', {
                  minLength: ImportSourceName.minLength,
                  maxLength: ImportSourceName.maxLength,
                  pattern: ImportSourceName.pattern,
                  required: true,
                })}
              />
              {!errors.source ? (
                <FormHelperText>
                  Input a source name (spaces not allowed)
                </FormHelperText>
              ) : (
                <FormErrorMessage>Invalid source name.</FormErrorMessage>
              )}
            </FormControl>
            <Button
              colorScheme="kbuttonblue"
              isDisabled={isLoading}
              m={4}
              type="submit"
            >
              Import
            </Button>
          </Flex>
        </Box>
        <Alert status="info">
          <AlertIcon />
          Source name must contain only letters and numbers, not have spaces and
          be between {ImportSourceName.minLength} and{' '}
          {ImportSourceName.maxLength} characters.
        </Alert>
      </Flex>
      {isLoading && <Spinner />}
    </Flex>
  );
};

const validationErrorText = (fileValidationError: FileValidationError) => {
  switch (fileValidationError) {
    case 'file_too_large':
      return 'File is too large. Max of 10K rows  per import.';
    case 'missing_columns':
      return (
        <Box>
          Required columns not found. Start with a
          <ImportTemplateLink text="CSV import template" />.
        </Box>
      );
  }
};

const ValidationErrorsStep = () => {
  const {setState, fileValidationErrors} = useImportModalStore();
  if (!fileValidationErrors) {
    Sentry.captureMessage('validation errors step but no errors detected');
    return <></>;
  }

  return (
    <Flex direction="column" width="100%">
      <FileValidationErrors
        errors={errorMap(fileValidationErrors, validationErrorText)}
      />
      <Flex direction="column" alignItems="center">
        <Button
          colorScheme="kbuttonblue"
          mt={8}
          onClick={() => {
            setState({
              selectedFile: null,
              step: 'chooseFile',
            });
          }}
        >
          Choose Another File
        </Button>
      </Flex>
    </Flex>
  );
};

interface RecommendedAccountsImportProps {
  isOpen: boolean;
  onClose: (taskId: string | null) => void;
}

export const RecommendedAccountsImportModal = ({
  isOpen,
  onClose,
}: RecommendedAccountsImportProps) => {
  const {step, reset} = useImportModalStore();

  const closeModal = (taskId: string | null) => {
    reset();
    onClose(taskId);
  };

  return (
    <Modal isCentered={true} isOpen={isOpen} onClose={() => closeModal(null)}>
      <ModalOverlay />
      <ModalContent minW="800" p={4}>
        <ModalHeader textAlign="center" fontSize="xl">
          Import Domains or LinkedIn URLs
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {step === 'chooseFile' && <FileChooserStep />}
          {step === 'validateFile' && <ValidateFileStep />}
          {step === 'uploadFile' && <UploadFileStep onClose={closeModal} />}
          {step === 'errors' && <ValidationErrorsStep />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const InfoBox = () => {
  return (
    <Box backgroundColor="kyellow.100" p={4} gap={4} width="100%">
      <Box alignItems="top">
        <WarningAlt
          style={{
            display: 'inline',
            marginRight: '4px',
          }}
        />
        Imports need to include a column for Domain, LinkedIn URL, or both.
        <ImportTemplateLink text="Download a CSV import template" />.
      </Box>
    </Box>
  );
};

const ImportTemplateLink = ({text}: {text: string}) => {
  return (
    <Link
      color="kblue.400"
      download={true}
      href="/Keyplay_RecommendedAccounts_Import_Template.csv"
      ml={1}
    >
      {text}
    </Link>
  );
};
