import {z} from 'zod';
import {GenericTaskSchema} from '../task';
import {ObjectId} from 'bson';
import {MarketSchema, SamDefinitionSchema, ScoringModelSchema} from '../market';
import {OnboardingTemplateRequestSchema} from '../onboarding';
import {
  AccountWithLogoSchema,
  CreditPriceResponseSchema,
  ScoringModelResponseSchema,
} from './responses';
import {PlanSchema} from '../customer';
import {AccountQuerySchema} from './api';
import {ModelTestFileSchema, ModelTestSchema} from '../scoring';

export type ApiOptions = {
  allowFreeCustomers?: boolean;
};

// The ApiDefinition is the contract between the client the server for a given route.
export interface ApiDefinition<
  RequestData extends z.ZodType = z.ZodType<unknown>,
  ResponseData extends z.ZodType = z.ZodType<unknown>,
> {
  method: 'get' | 'post';
  options?: ApiOptions;
  requestDataSchema: RequestData;
  responseDataSchema: ResponseData;
}

export const GetTasks = {
  method: 'get',
  requestDataSchema: z.object({
    partialCustomerName: z.string().optional(),
  }),
  responseDataSchema: GenericTaskSchema.array(),
} satisfies ApiDefinition;

export const CancelTask = {
  method: 'post',
  requestDataSchema: z.object({
    taskName: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetSamDefinition = {
  method: 'get',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    marketId: z.string(),
  }),
  responseDataSchema: SamDefinitionSchema,
} satisfies ApiDefinition;

export const UpdateSamDefinition = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    marketId: z.instanceof(ObjectId),
    samDefinition: SamDefinitionSchema,
  }),
  responseDataSchema: SamDefinitionSchema,
} satisfies ApiDefinition;

export const SetOnboardingTemplate = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: OnboardingTemplateRequestSchema,
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetScoringModel = {
  method: 'get',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    marketId: z.string(),
  }),
  responseDataSchema: ScoringModelResponseSchema,
} satisfies ApiDefinition;

export const PostScoringModel = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    marketId: z.instanceof(ObjectId),
    updates: ScoringModelSchema.partial(),
    triggerRefresh: z.boolean().optional(),
  }),
  responseDataSchema: ScoringModelSchema.partial(),
} satisfies ApiDefinition;

export const BulkFindLookalikes = {
  method: 'post', // POST instead of GET due to the potential size of the request
  options: {allowFreeCustomers: true},
  requestDataSchema: z.string().array(),
  responseDataSchema: AccountWithLogoSchema.array(),
} satisfies ApiDefinition;

export const GetCreditPrices = {
  method: 'get',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({}),
  responseDataSchema: CreditPriceResponseSchema,
} satisfies ApiDefinition;

export const CreateCheckoutSession = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    priceId: z.string(),
  }),
  responseDataSchema: z.object({
    url: z.string(),
  }),
} satisfies ApiDefinition;

export const ClaimBonusCode = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    bonusCode: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetCustomers = {
  method: 'get',
  requestDataSchema: z.object({}),
  responseDataSchema: z
    .object({
      _id: z.instanceof(ObjectId),
      name: z.string(),
      plan: PlanSchema,
    })
    .array(),
} satisfies ApiDefinition;

export const ClayExport = {
  method: 'post',
  options: {allowFreeCustomers: true},
  requestDataSchema: z.object({
    query: z.optional(AccountQuerySchema),
    webhookUrl: z.string(),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

export const GetModelTest = {
  method: 'get',
  requestDataSchema: z.object({
    marketId: z.string(),
  }),
  responseDataSchema: ModelTestSchema.extend({
    fileName: z.string(),
  }).nullish(),
} satisfies ApiDefinition;

export const PostModelTest = {
  method: 'post',
  requestDataSchema: z.object({
    file: ModelTestFileSchema,
    marketId: z.instanceof(ObjectId),
  }),
  responseDataSchema: z.void(),
} satisfies ApiDefinition;

// TODO: move this const if we request/response types in their own file
export const MaxValidateDomainRows = 5_000;
export const ValidateDomains = {
  method: 'post',
  requestDataSchema: z.array(
    z
      .object({
        domain: z.string(),
      })
      .catchall(z.unknown())
  ),
  responseDataSchema: z
    .object({
      domain: z.string(),
      finalDomain: z.string().optional(),
      status: z.string(),
    })
    .catchall(z.unknown())
    .array(),
} satisfies ApiDefinition;

export const PostMarket = {
  method: 'post',
  requestDataSchema: z.object({
    marketId: z.instanceof(ObjectId),
    samDefinition: SamDefinitionSchema,
    scoringModel: ScoringModelSchema.partial(),
    triggerRefresh: z.boolean().optional(),
  }),
  responseDataSchema: MarketSchema,
  options: {allowFreeCustomers: true},
} satisfies ApiDefinition;
