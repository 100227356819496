import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useKeyplayApi} from '../../context/ApiContext';
import {
  SimilarAccountSizeRequest,
  SimilarAccountSizeResponse,
} from '../../shared/market';
import {
  useCustomer,
  useIsFreeOrListBuilderCustomer,
} from '../../hooks/api/metadata';
import {ObjectId} from 'bson';
import {useMarketId} from '../../components/Market/MarketIdProvider';
import {usePublishScoringModel} from './scoringModel';
import {useApiMutation} from './api';
import {BulkFindLookalikes} from '../../shared/api/definitions';
import {ScoringInfoQueryKey} from './scoringInfo';
import {MetadataQueryKey} from '../../context/MetadataContext';

type SimilarAccountsSizeProps = {
  enabled?: boolean;
} & SimilarAccountSizeRequest;

// Get the number of similar accounts for the given set of lookalikes.
export function useSimilarAccountsSize({
  enabled,
  lookalikeIds,
}: SimilarAccountsSizeProps) {
  const makeApiCall = useKeyplayApi();
  return useQuery(
    ['similarAccountsSize', JSON.stringify(lookalikeIds)],
    () =>
      makeApiCall<SimilarAccountSizeResponse>('/similarAccountsSize', {
        data: {lookalikeIds},
        method: 'POST',
      }),
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useSaveLookalikesMutation() {
  const queryClient = useQueryClient();
  const customer = useCustomer();
  const isFreeOrListBuilderCustomer = useIsFreeOrListBuilderCustomer();
  const marketId = useMarketId();
  const publishScoringModel = usePublishScoringModel();

  return useMutation({
    mutationFn: async (lookalikeIds: ObjectId[]) => {
      await publishScoringModel.mutateAsync(
        {
          marketId,
          updates: {lookalikes: lookalikeIds},
        },
        {
          onSuccess: async () => {
            if (customer.plan.type === 'free') {
              // Update customer limits if on the free plan.
              await queryClient.invalidateQueries([MetadataQueryKey]);
            }
            if (isFreeOrListBuilderCustomer) {
              await queryClient.invalidateQueries([ScoringInfoQueryKey]);
            }
          },
        }
      );
    },
  });
}

const BulkdFindLookalikesMutationKey = ['bulkFindLookalikes'];

export const useBulkFindLookalikesMutation = () => {
  return useApiMutation('/bulkFindLookalikes', BulkFindLookalikes, {
    mutationKey: BulkdFindLookalikesMutationKey,
  });
};

export const useIsBulkFindLookalikesLoading = () =>
  useIsMutating(BulkdFindLookalikesMutationKey) > 0;
