import _ from 'lodash';
import {KeyplayScoringSignal, ResolvedScoringSignal} from './signals';
import {constructZodEnumType, keys} from './util';
import {AccountTier, CustomerAccountSource, Tag} from './scoredAccounts';
import {AccountFieldLabels} from './fields';
import {
  BusinessAudience,
  PrimaryBusinessCategory,
  SecondaryBusinessCategory,
} from './account';
import {WithId} from './util';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {KeyplayStatus} from './accountStatus';
import {ObjectId} from 'bson';
import {CountryCode} from './countries';

export interface EnrichmentFieldTypes {
  audience: BusinessAudience[] | undefined;
  businessOverview: string | undefined;
  employeeCount: number | undefined;
  finalDomain: string | undefined;
  finalLinkedIn: string | undefined;
  fit: number;
  hiring: number | undefined;
  hiringEng: number | undefined;
  hiringSales: number | undefined;
  hiringSoftwareEng: number | undefined;
  hqCity: string | undefined;
  hqCountry: CountryCode | undefined;
  hqPostalCode: string | undefined;
  hqState: string | undefined;
  inSam: boolean | undefined;
  jobUrl: string | undefined;
  lastFundingDate: string | undefined;
  linkedInJobCount: number | undefined;
  mostSimilarTo: string | undefined;
  name: string | undefined;
  oneMonthHeadcountGrowth: number | undefined;
  oneMonthHiringEngChange: number | undefined;
  oneMonthHiringHRChange: number | undefined;
  oneMonthHiringProductChange: number | undefined;
  oneMonthOpenRolesChange: number | undefined;
  oneMonthRecruitingVelocityChange: number | undefined;
  oneMonthTotalFundingChange: number | undefined;
  openRolePercentage: number | undefined;
  org: ResolvedScoringSignal[];
  primaryCategory: PrimaryBusinessCategory[] | undefined;
  profile: ResolvedScoringSignal[];
  recruitingVelocity: number | undefined;
  relevance: ResolvedScoringSignal[];
  secondaryCategory: SecondaryBusinessCategory[] | undefined;
  signals: ResolvedScoringSignal[];
  signalScore: number | undefined;
  similarityScore: number | undefined;
  sixMonthHeadcountGrowth: number | undefined;
  slackCommunityPage: string | undefined;
  source: CustomerAccountSource | undefined;
  stackFit: ResolvedScoringSignal[];
  status: KeyplayStatus;
  tags: WithId<Tag>[];
  tier: AccountTier | undefined;
  totalFundingRaised: number | undefined;
  yearFounded: number | undefined;

  // customer specific
  iterable_b2bSignals: KeyplayScoringSignal[];
  iterable_b2cSignals: KeyplayScoringSignal[];

  hackerRank_hiringHackerRankSkills: number | undefined;
  hackerRank_hiringHackerRankSkillsEmea: number | undefined;
  hackerRank_hiringHackerRankSkillsApac: number | undefined;
  hackerRank_hiringHackerRankSkillsAmer: number | undefined;
}
export type EnrichmentField = keyof EnrichmentFieldTypes;

export const EnrichmentFieldLabels = {
  audience: 'Audience',
  businessOverview: 'Business Overview',
  employeeCount: AccountFieldLabels.employeeCount,
  finalDomain: AccountFieldLabels.finalDomain,
  finalLinkedIn: AccountFieldLabels.finalLinkedIn,
  fit: 'Overall Fit',
  hiring: AccountFieldLabels.hiring,
  hiringEng: AccountFieldLabels.hiringEng,
  hiringSales: AccountFieldLabels.hiringSales,
  hiringSoftwareEng: AccountFieldLabels.hiringSoftwareEng,
  hqCity: AccountFieldLabels.hqCity,
  hqCountry: AccountFieldLabels.hqCountry,
  hqPostalCode: AccountFieldLabels.hqPostalCode,
  hqState: AccountFieldLabels.hqState,
  inSam: 'In SAM',
  jobUrl: 'Job URL',
  lastFundingDate: AccountFieldLabels.lastFundingDate,
  linkedInJobCount: AccountFieldLabels.linkedInJobCount,
  mostSimilarTo: 'Most Similar To',
  name: AccountFieldLabels.name,
  oneMonthHeadcountGrowth: AccountFieldLabels.oneMonthHeadcountGrowth,
  oneMonthHiringEngChange: AccountFieldLabels.oneMonthHiringEngChange,
  oneMonthHiringHRChange: AccountFieldLabels.oneMonthHiringHRChange,
  oneMonthHiringProductChange: AccountFieldLabels.oneMonthHiringProductChange,
  oneMonthOpenRolesChange: AccountFieldLabels.oneMonthOpenRolesChange,
  oneMonthRecruitingVelocityChange:
    AccountFieldLabels.oneMonthRecruitingVelocityChange,
  oneMonthTotalFundingChange: AccountFieldLabels.oneMonthTotalFundingChange,
  openRolePercentage: AccountFieldLabels.openRolePercentage,
  org: 'Org Signals',
  primaryCategory: 'Primary Categories',
  profile: 'Profile Signals',
  recruitingVelocity: AccountFieldLabels.recruitingVelocity,
  relevance: 'Relevance Signals',
  secondaryCategory: 'Secondary Categories',
  signals: 'Signals Matched',
  signalScore: 'Signal Score',
  similarityScore: 'Similarity Score',
  sixMonthHeadcountGrowth: AccountFieldLabels.sixMonthGrowth,
  slackCommunityPage: AccountFieldLabels.slackCommunityPage,
  source: 'Source',
  stackFit: 'Stack Signals',
  status: 'Status',
  tags: 'Tags',
  tier: 'Tier',
  totalFundingRaised: AccountFieldLabels.totalFundingRaised,
  yearFounded: 'Year Founded',

  // Note: These must match the corresponding rollup names.
  iterable_b2bSignals: 'B2B Signals',
  iterable_b2cSignals: 'B2C Signals',

  hackerRank_hiringHackerRankSkills: 'Hiring HackerRank Skills',
  hackerRank_hiringHackerRankSkillsEmea: 'Hiring HackerRank Skills - EMEA',
  hackerRank_hiringHackerRankSkillsApac: 'Hiring HackerRank Skills - APAC',
  hackerRank_hiringHackerRankSkillsAmer: 'Hiring HackerRank Skills - AMER',
} as const satisfies Record<EnrichmentField, string>;

// All supported Keyplay enrichment fields
export const EnrichmentFields = keys(EnrichmentFieldLabels);

// Standard enrichment fields are enabled by default and automatically managed by Keyplay.
const StandardEnrichmentFields = [
  // Note: The order here determines the order in which fields are displayed in the UI.
  'finalDomain',
  'tier',
  'fit',
  'signals',
  'primaryCategory',
  'secondaryCategory',
  'audience',
  'tags',
  'profile',
  'org',
  'relevance',
  'stackFit',
  'source',
  'status',
  'signalScore',
  'similarityScore',
  'mostSimilarTo',
  'hqCountry',
  'inSam',

  'iterable_b2bSignals',
  'iterable_b2cSignals',

  'hackerRank_hiringHackerRankSkills',
  'hackerRank_hiringHackerRankSkillsEmea',
  'hackerRank_hiringHackerRankSkillsApac',
  'hackerRank_hiringHackerRankSkillsAmer',
] as const satisfies readonly EnrichmentField[];

export const StandardEnrichmentField = constructZodEnumType(
  StandardEnrichmentFields
);
export type StandardEnrichmentField = z.infer<typeof StandardEnrichmentField>;
export const isStandardEnrichmentField = zodTypeguard(StandardEnrichmentField);

// Customer-specific standard fields.
export const OptionalStandardEnrichmentFields = [
  'profile',
  'org',
  'relevance',
  'stackFit',

  'iterable_b2bSignals',
  'iterable_b2cSignals',
  'hackerRank_hiringHackerRankSkills',
  'hackerRank_hiringHackerRankSkillsEmea',
  'hackerRank_hiringHackerRankSkillsApac',
  'hackerRank_hiringHackerRankSkillsAmer',
] as const satisfies readonly StandardEnrichmentField[];

// Available to all customers.
export const CoreStandardEnrichmentFields = _.difference(
  StandardEnrichmentFields,
  OptionalStandardEnrichmentFields
);

export const StandardEnrichmentFieldCrmNames = {
  audience: 'keyplay_audience',
  finalDomain: 'keyplay_final_domain',
  fit: 'keyplay_fit',
  hqCountry: 'keyplay_hq_country',
  inSam: 'keyplay_in_sam',
  mostSimilarTo: 'keyplay_similar_accounts',
  org: 'keyplay_org_signals',
  primaryCategory: 'keyplay_primary_categories',
  profile: 'keyplay_profile_signals',
  relevance: 'keyplay_relevance_signals',
  secondaryCategory: 'keyplay_secondary_categories',
  signalScore: 'keyplay_signal_score',
  signals: 'keyplay_signals',
  similarityScore: 'keyplay_similarity_score',
  source: 'keyplay_source',
  stackFit: 'keyplay_stack_signals',
  status: 'keyplay_status',
  tags: 'keyplay_tags',
  tier: 'keyplay_tier',

  iterable_b2bSignals: 'keyplay_iterable_b2b_signals',
  iterable_b2cSignals: 'keyplay_iterable_b2c_signals',

  hackerRank_hiringHackerRankSkills: 'keyplay_hiring_hackerrank_skills',
  hackerRank_hiringHackerRankSkillsEmea:
    'keyplay_hiring_hackerrank_skills_emea',
  hackerRank_hiringHackerRankSkillsApac:
    'keyplay_hiring_hackerrank_skills_apac',
  hackerRank_hiringHackerRankSkillsAmer:
    'keyplay_hiring_hackerrank_skills_amer',
} as const satisfies Record<StandardEnrichmentField, string>;

export const StandardEnrichmentFieldCrmLabels = {
  audience: 'Keyplay Audience',
  finalDomain: 'Keyplay Final Domain',
  fit: 'Keyplay Fit Score',
  hqCountry: 'Keyplay HQ Country',
  inSam: 'Keyplay In SAM',
  mostSimilarTo: 'Keyplay Most Similar To',
  org: 'Keyplay Org Signals',
  primaryCategory: 'Keyplay Categories',
  profile: 'Keyplay Profile Signals',
  relevance: 'Keyplay Relevance Signals',
  secondaryCategory: 'Keyplay Secondary Categories',
  signals: 'Keyplay Signals',
  signalScore: 'Keyplay Signal Score',
  similarityScore: 'Keyplay Similarity Score',
  source: 'Keyplay Source',
  stackFit: 'Keyplay Stack Signals',
  status: 'Keyplay Status',
  tags: 'Keyplay Tags',
  tier: 'Keyplay Tier',

  iterable_b2bSignals: 'Keyplay B2B Signals',
  iterable_b2cSignals: 'Keyplay B2C Signals',

  hackerRank_hiringHackerRankSkills: 'Keyplay Hiring HackerRank Skills',
  hackerRank_hiringHackerRankSkillsEmea:
    'Keyplay Hiring HackerRank Skills - EMEA',
  hackerRank_hiringHackerRankSkillsApac:
    'Keyplay Hiring HackerRank Skills - APAC',
  hackerRank_hiringHackerRankSkillsAmer:
    'Keyplay Hiring HackerRank Skills - AMER',
} as const satisfies Record<StandardEnrichmentField, string>;

const AdditionalEnrichmentFields = [
  'businessOverview',
  'employeeCount',
  'finalDomain',
  'finalLinkedIn',
  'fit',
  'hiring',
  'hiringEng',
  'hiringSales',
  'hiringSoftwareEng',
  'hqCity',
  'hqCountry',
  'hqPostalCode',
  'hqState',
  'jobUrl',
  'lastFundingDate',
  'linkedInJobCount',
  'name',
  'oneMonthHeadcountGrowth',
  'oneMonthHiringEngChange',
  'oneMonthHiringHRChange',
  'oneMonthHiringProductChange',
  'oneMonthOpenRolesChange',
  'oneMonthRecruitingVelocityChange',
  'oneMonthTotalFundingChange',
  'openRolePercentage',
  'recruitingVelocity',
  'signalScore',
  'similarityScore',
  'sixMonthHeadcountGrowth',
  'slackCommunityPage',
  'tier',
  'totalFundingRaised',
  'yearFounded',
] as const satisfies readonly EnrichmentField[];

export const AdditionalEnrichmentField = constructZodEnumType(
  AdditionalEnrichmentFields
);
export type AdditionalEnrichmentField = z.infer<
  typeof AdditionalEnrichmentField
>;
export const isAdditionalEnrichmentField = zodTypeguard(
  AdditionalEnrichmentField
);

// Customer-specific additional fields.
export const OptionalAdditionalEnrichmentFields = [
  'linkedInJobCount',
  'slackCommunityPage',
] as const satisfies readonly AdditionalEnrichmentField[];

// Available to all customers.
export const CoreAdditionalEnrichmentFields = _.difference(
  AdditionalEnrichmentFields,
  OptionalAdditionalEnrichmentFields
);

// Standard fields are fully managed by Keyplay.
// Additional fields are managed by the customer.
// - They can either be built-in fields (e.g. employee count) or custom fields.
const EnrichmentFieldTypeSchema = z.union([
  z.literal('standard'),
  z.literal('additional'),
]);
export type EnrichmentFieldType = z.infer<typeof EnrichmentFieldTypeSchema>;

export const MultiMarketFields = [
  'fit',
  'similarityScore',
  'signalScore',
  'tier',
] satisfies readonly AdditionalEnrichmentField[];

const EnrichmentFieldBaseConfigSchema = z.object({
  crmFieldName: z.string(),
  enrichmentFieldType: EnrichmentFieldTypeSchema,
  enabled: z.boolean(),
  override: z.boolean(),
  kind: z.string(),
  marketId: z.instanceof(ObjectId).optional(),
});

// Standard enrichment field config types.

const StandardEnrichmentFieldConfigSchema =
  EnrichmentFieldBaseConfigSchema.extend({
    enrichmentFieldType: z.literal('standard'),
    enrichmentField: StandardEnrichmentField,
    kind: z.literal('keyplay'),
  });
export type StandardEnrichmentFieldConfig = z.infer<
  typeof StandardEnrichmentFieldConfigSchema
>;
export function isStandardEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is StandardEnrichmentFieldConfig {
  return config.enrichmentFieldType === 'standard' && config.kind === 'keyplay';
}

// Additional enrichment field config types.

const AdditionalEnrichmentFieldConfigSchema =
  EnrichmentFieldBaseConfigSchema.extend({
    enrichmentFieldType: z.literal('additional'),
  });

const AdditionalKeyplayEnrichmentFieldConfigSchema =
  AdditionalEnrichmentFieldConfigSchema.extend({
    kind: z.literal('keyplay'),
    enrichmentField: AdditionalEnrichmentField,
  });
export type AdditionalKeyplayEnrichmentFieldConfig = z.infer<
  typeof AdditionalKeyplayEnrichmentFieldConfigSchema
>;
export function isAdditionalKeyplayEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is AdditionalKeyplayEnrichmentFieldConfig {
  return (
    config.enrichmentFieldType === 'additional' && config.kind === 'keyplay'
  );
}

const CustomEnrichmentFieldConfigSchema =
  AdditionalEnrichmentFieldConfigSchema.extend({
    kind: z.literal('custom'),
    customFieldId: z.instanceof(ObjectId),
  });
export type CustomEnrichmentFieldConfig = z.infer<
  typeof CustomEnrichmentFieldConfigSchema
>;
export function isCustomEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is CustomEnrichmentFieldConfig {
  return (
    config.enrichmentFieldType === 'additional' && config.kind === 'custom'
  );
}

export type AdditionalEnrichmentFieldConfig =
  | AdditionalKeyplayEnrichmentFieldConfig
  | CustomEnrichmentFieldConfig;
export function isAdditionalEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is AdditionalEnrichmentFieldConfig {
  return config.enrichmentFieldType === 'additional';
}

// Type guard for strictly Keyplay enrichment fields (standard or additional).
export function isKeyplayEnrichmentFieldConfig(
  config: EnrichmentFieldConfig
): config is
  | AdditionalKeyplayEnrichmentFieldConfig
  | StandardEnrichmentFieldConfig {
  return config.kind === 'keyplay';
}

export type EnrichmentFieldConfig =
  | AdditionalEnrichmentFieldConfig
  | StandardEnrichmentFieldConfig;

export type OneWaySyncSetting = {
  type: 'oneWay';
};
export type TwoWaySyncSetting = {
  type: 'twoWay';
  pushSavedAccounts: boolean;
};
export type SelectiveTwoWaySyncSetting = {
  type: 'selectiveTwoWay';
  pushSavedAccounts: boolean;
};

export type SyncSettings =
  | OneWaySyncSetting
  | TwoWaySyncSetting
  | SelectiveTwoWaySyncSetting;
export type SyncType = SyncSettings['type'];

export interface IntegrationConfig {
  autoSync?: boolean;
  syncSettings?: SyncSettings;
  fieldMapping?: EnrichmentFieldConfig[];
  optionalFields?: EnrichmentField[]; // Customer-specific fields. Can be standard or additional.

  // deprecated fields
  pushSavedAccountsToCrm?: boolean;
  pullAndSyncCrmRecords?: boolean;
}

export type IntegrationStatus =
  | 'active'
  | 'disconnected'
  | 'refresh_token_expired';

export const CrmSyncDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
] as const;

export type CrmSyncDay = (typeof CrmSyncDays)[number];

export interface BaseOAuthData {
  issuedAt: Date;
  refreshToken: string;
  tokenType: string;
}

export type OAuthDataMap = {
  hubspot: BaseOAuthData & {
    expiresIn: number;
  };
  salesforce: BaseOAuthData & {
    id: string;
    instanceUrl: string;
    scope: string;
    signature: string;
    isSandbox?: boolean;
  };
};

export type OAuthIntegrationType = keyof OAuthDataMap;

export interface OAuthIntegration<T extends OAuthIntegrationType> {
  id: ObjectId;
  type: T;
  timestamp: Date;

  crmSyncDay: CrmSyncDay;
  oauth: OAuthDataMap[T];
  status: IntegrationStatus;

  config?: IntegrationConfig;
  lastEnrichment?: Date;
  lastSync?: Date;
}

export type SalesforceIntegration = OAuthIntegration<'salesforce'>;
export type HubSpotIntegration = OAuthIntegration<'hubspot'>;
export type AnyCrmIntegration = SalesforceIntegration | HubSpotIntegration;

export interface CrmField {
  name: string; // The unique name of the field.
  label: string; // The display label of the field.
  type: string; // The data type of the field (e.g. string, number).

  // The fieldType corresponds to visual appearance of the field (e.g. checkbox, radio).
  // A given type may have multiple valid fieldTypes.
  // https://developers.hubspot.com/docs/api/crm/properties
  fieldType: string;
}
